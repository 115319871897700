import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Characterbanner from "../assets/imgs/banners/character-banner.png";
import characterbruce from "../assets/imgs/characters/charbruce.png";
import charactergibran from "../assets/imgs/characters/chargibran.png";
import characterivy from "../assets/imgs/characters/charivy.png";
import charactersteel from "../assets/imgs/characters/charsteel.png";
import characternina from "../assets/imgs/characters/charnina.png";
import characterx from "../assets/imgs/characters/charx.png";
import characterwolf from "../assets/imgs/characters/charwolf.png";
import charactervision from "../assets/imgs/characters/charvision.png";
import characterpsycho from "../assets/imgs/characters/charpsycho.png";

import Characterpageslider from "./sliders/Characterpageslider";
import { Link } from "react-router-dom";
import Relatedposts from "./relatedposts";

export default class Characters extends Component {
  render() {
    return (
      <>
        <Header />
        <div class="containers">
          <img style={{ width: '100%' }} src={Characterbanner} alt="Snow" />

          <div class="centered">  <div className="ch-bann-txt-wrap">
            <h2 className="character-banner-heading">
              <span className="spancoloryellow">SUPER VET </span>
              CHARACTERS
            </h2>
            <h3 className="character-banner-text">
              We Are Here To Rescue Helpless Animals
            </h3>
          </div></div>
        </div>


        <div className="main-bg character">
          <div className="call-to-action">
            <div className="container">
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit featured-characters">
                      FEATURED cHARACTERS
                    </h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="Charactersection" className="comics-section">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-character ">
                    <Link to="/bruceprofile" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterbruce}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.BRUCE</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={charactergibran}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Gibran</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characternina}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">Sister Nina</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={charactersteel}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Steelshot</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterivy}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Ivy</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <img src="../../public/assets/imgs/b1.png" alt="" />
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">Character spotlight</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <Relatedposts></Relatedposts>

          <div className="call-to-action">
            <div className="container">
              <img src="../../public/assets/imgs/b1.png" alt="" />
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">cHARACTERS LIST</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="Charactersearch">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-character ">
                    <Link to="/bruceprofile" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterbruce}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.BRUCE</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={charactergibran}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Gibran</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characternina}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">Sister Nina</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={charactersteel}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Steelshot</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterivy}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Ivy</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterx}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.X</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterpsycho}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Psycho</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={charactervision}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">MR.Vision</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img
                          className="single-comic-img"
                          src={characterwolf}
                          alt=""
                        />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">DR.Wolf</h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="chara-page-slider" className="character-page-slider">
            <Characterpageslider />
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
