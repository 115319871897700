import React, { Component } from "react";
import spot1 from "../assets/imgs/characterspotlight/spot1.png";
import spot2 from "../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../assets/imgs/characterspotlight/spot4.png";
import { Link } from "react-router-dom";

export default class Relatedposts extends Component {
    render() {
        return (
            <>
                <div className="character-spotlight">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3">
                                <Link to="/post1">
                                    <div className="spot-wrapper">
                                        <img src={spot1} alt="" />
                                        <h3 className="spot-tit1">SUPER COMICS</h3>
                                        <h3 className="spot-tit2">When 'What If?’ Comes True</h3>
                                    </div>
                                </Link>

                            </div>

                            <div className="col-sm-3">
                                <Link to="/post4">
                                    <div className="spot-wrapper">
                                        <img src={spot2} alt="" />
                                        <h3 className="spot-tit1">TRAILERS & EXTRAS</h3>
                                        <h3 className="spot-tit2">Who’s Gona Help Under Sea</h3>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-sm-3">
                                <Link to="/post2">
                                    <div className="spot-wrapper">
                                        <img src={spot3} alt="" />
                                        <h3 className="spot-tit1">SUPER COMICS</h3>
                                        <h3 className="spot-tit2">First Appearances: Vision</h3>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-sm-3">
                                <Link to="/post3">
                                    <div className="spot-wrapper">
                                        <img src={spot4} alt="" />
                                        <h3 className="spot-tit1">Amazing Comics</h3>
                                        <h3 className="spot-tit2">Super Heroes</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
