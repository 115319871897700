import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import team1 from "../../assets/imgs/characters/team/team1.png";
import team2 from "../../assets/imgs/characters/team/team2.png";
import team3 from "../../assets/imgs/characters/team/team3.png";
import team4 from "../../assets/imgs/characters/team/team4.png";
import team5 from "../../assets/imgs/characters/team/team5.png";
import team6 from "../../assets/imgs/characters/team/team6.png";
import team7 from "../../assets/imgs/characters/team/team7.png";
import team8 from "../../assets/imgs/characters/team/team8.png";
import team9 from "../../assets/imgs/characters/team/team9.png";
import joinimg from "../../assets/imgs/character-profile-tabs/join-now.png";
import { Link } from "react-router-dom";
import Characterbanner from "../../assets/imgs/banners/character-banner.png";

export default class Supervetteam extends Component {
  render() {
    return (
      <>
        <Header />
        <div class="containers">
          <img style={{ width: '100%' }} src={Characterbanner} alt="Snow" />

          <div class="centered">  <div className="ch-bann-txt-wrap">
            <h2 className="character-banner-heading">
              <span className="spancoloryellow">SUPER VET </span>
              TEAMS & GROUPS
            </h2>
            <h3 className="character-banner-text">
              We Are Here To Rescue Helpless Animals
            </h3>
          </div></div>
        </div>


        <div className="main-bg team">
          <div className="call-to-action">
            <div className="container">
              <img src="../../public/assets/imgs/b1.png" alt="" />
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit featured-characters">
                      FEATURED Teams
                    </h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="Charactersection" className="comics-section">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-character ">
                    <Link to="#" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team1} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">save the kind</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team2} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">me and you</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team3} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">The Duo Saviour</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team4} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">one-man two-man</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team5} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">be my eyes</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="playandearnsection">
              <div className="row">
                <h3 className="letsplayandearn">Let’s Play And Earn !</h3>
                <button className="letsplayandearnbtn">PLAY</button>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <img src="../../public/assets/imgs/b1.png" alt="" />
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">Group LIST</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="Charactersearch">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-character ">
                    <Link to="#" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team1} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">save the kind</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team2} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">me and you</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team3} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">The Duo Saviour</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team4} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">one-man two-man</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team5} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">be my eyes</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team6} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">1st recruit</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team7} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">fun in jungle</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team8} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">together</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-character">
                    <Link to="/characters" className="single-links">
                      <div className="single-character-wrapper">
                        <img className="single-comic-img" src={team9} alt="" />
                      </div>
                      <div className="for-border-anime"></div>
                      <div className="single-character-text-wrapper">
                        <h3 className="single-title">team lone wolf</h3>
                        <div className="nameandprice">
                          <div className="name">Super Vet</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="join-us-metamsk-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-7 nopaddingright">
                  <img className="join-img" src={joinimg} />
                </div>
                <div className="col-sm-5 nopaddingleft">
                  <div className="text-to-align-middle forbg-overlay">
                    <div className="text-wrapper-r">
                      <h3 className="join-us-now">JOIN US</h3>
                      <p className="join-text">
                        READ SUPER VET COMICS AND EARN REWARD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
