import React, { Component } from "react";
import Brucevol1 from "../assets/imgs/bruce.png";
import frontgold from "../assets/imgs/FRONTGOLD.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import Comicslider from "./SuperComics-slider";
import Videoslider from "./Video-slider";
import Readforrentslider from "./Readforrent-slider";
import svunl from "../assets/imgs/svunlimited.png";
import Psycho from "../assets/imgs/psycho.png";
import Vision from "../assets/imgs/mr-vision.jpg";
import Gibran from "../assets/imgs/dr-jibran.jpg";
import Ivy from "../assets/imgs/ivy.jpg";
import Drx from "../assets/imgs/dr-x.jpg";
import { Link } from "react-router-dom";

export default class Homebody extends Component {
  render() {
    return (
      <div className="main-bg homebody">
        <div className="main-bg-overlay">
          <div className="call-to-action">
            <div className="container">
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">New Releases</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center">

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="comics-section">
            <div className="container">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-comic">
                    <Link to="/Bruce" className="single-links">
                      <p className="new-lable">NEW</p>
                      <img
                        className="single-comic-img"
                        src={Brucevol1}
                        alt=""
                      />
                      <h3 className="single-title">
                        DR.BRUCE VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            Free
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-comic">
                    <Link to="/nina" className="single-links">

                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Nina} alt="" />
                      <h3 className="single-title">
                        Sister Nina VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            Free
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Steelshot} alt="" />
                    <h3 className="single-title">
                      DR.Steelshot VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Wolf} alt="" />
                    <h3 className="single-title">
                      DR.Wolf VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Psycho} alt="" />
                    <h3 className="single-title">
                      DR.Psycho VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Gibran} alt="" />
                    <h3 className="single-title">
                      DR.Gibran VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Vision} alt="" />
                    <h3 className="single-title">
                      MR.Vision VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Ivy} alt="" />
                    <h3 className="single-title">DR.IVY VOL 1 PILOT EPISODE</h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Drx} alt="" />
                    <h3 className="single-title">DR.X VOL 1 PILOT EPISODE</h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Brucevol1} alt="" />
                    <h3 className="single-title">
                      DR.BRUCE VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          Slider1
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <img src="../../public/assets/imgs/b1.png" alt="" />
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">Super Comics</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center">
                    <Link className="call-to-btn" to="/supercomics">
                      See All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="comics-slider">
            <div className="container">
              <div className="row">
                <div className="for-border">
                  <Comicslider />
                </div>
              </div>
            </div>
          </div>

          <div className="call-to-action">
            <div className="container">
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit">Video Comics</h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center">
                    <a className="call-to-btn" href="/supervideocomics">
                      See All
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="video-slider">
            <div className="container">
              <div className="row">
                <div className="for-border">
                  <Videoslider />
                </div>
              </div>
            </div>
          </div>
          <Link to="/unlimited">
            <div className="call-to-action unlimited">
              <div className="container">
                <div className="rectangle-cta unlimited">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="for-rectangle-gd-bg">

                        <img className="svunlimited" src={svunl} alt="" />

                      </div>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Want to Read all Super Vet comics ! super vet gave you a
                        chance to read all by purchasing super vet comics bundle.
                      </p>
                    </div>
                    <div className="col-sm-3 for-center">
                      <p className="read-unl">
                        Read Unlimited
                      </p>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>


          <div className="call-to-action">
            <div className="container">
              <Link className="call-to-btn" to="/onrent">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 nopaddingleft">
                      <h4 className="call-to-tit">read for rent</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center">

                      Join Now

                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="readforrent-slider">
            <div className="container">
              <div className="row">
                <div className="for-border">
                  <Readforrentslider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
