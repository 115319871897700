import React, { Component } from "react";
import Headerquiztwo from "../Headerquiztwo";
export default class Claimreward extends Component {
    render() {
        return (
            <>
                <Headerquiztwo />
                <img style={{ width: '100%' }} src="../../../assets/imgs/reward.png" alt="" />
            </>
        );
    }
}
