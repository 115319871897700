import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import image1 from "../../assets/imgs/character-profile-tabs/image1.png";
import image2 from "../../assets/imgs/character-profile-tabs/image2.png";
import image3 from "../../assets/imgs/character-profile-tabs/image3.png";
import { Link } from "react-router-dom";
import charactergibran from "../../assets/imgs/characters/chargibran.png";
import characterivy from "../../assets/imgs/characters/charivy.png";
import charactersteel from "../../assets/imgs/characters/charsteel.png";
import characternina from "../../assets/imgs/characters/charnina.png";
import characterx from "../../assets/imgs/characters/charx.png";
import joinimg from "../../assets/imgs/character-profile-tabs/join-now.png";
import drbruce from "../../assets/imgs/character-profile-tabs/drbruce.png";
import Charactenemy from "../../assets/imgs/characters/BOOGYVET.png";
import iconquestion from "../../assets/imgs/whoisnext.png";
import commingsoon from "../../assets/imgs/commingsoon.png";
import Brucevol1 from "../../assets/imgs/bruce.png";
import Brucevol2 from "../../assets/imgs/volume2covers/dr-bruce-vol-2.jpg";
import videoico from "../../assets/imgs/videoicon.png";
import Relatedposts from "../relatedposts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Tabss() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderTop: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label=" tabs " >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="In Game-Profile" {...a11yProps(1)} />
          <Tab label=" All Comics" {...a11yProps(2)} />
          <Tab label=" Video Comics" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">WATCH NOW</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <img className="char-profile-tab-img" src={image1} alt="" />
            </div>
            <div className="col-sm-4">
              <img className="char-profile-tab-img" src={image2} alt="" />
            </div>
            <div className="col-sm-4">
              <img className="char-profile-tab-img" src={image3} alt="" />
            </div>
          </div>
        </div>
        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Connections</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>

        <div id="Charactersection" className="comics-section">
          <div className="container-xxl">
            <div className="row">
              <div className="comics-wrapper">
                <div className="single-character">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={charactergibran}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">DR.Gibran</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="single-character">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={characternina}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">Sister Nina</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="single-character">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={charactersteel}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">DR.Steelshot</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="single-character">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={characterivy}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">DR.Ivy</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="single-character ">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={characterx}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">DR.X</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="drbrucesection">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <img className="width100" src={drbruce} alt="" />
              </div>

              <div className="col-sm-5">
                <div className="text-to-align-middle justify-content-center">
                  <div className="text-wrapper-r">
                    <h3 className="join-us-now">DR.Bruce</h3>
                    <p className="join-text">
                      READ SUPER VET COMICS AND EARN REWARD
                    </p>
                    <div className="btn-wrappers">
                      <a href="#" class="single-comics-btns readprofile">
                        Read Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="call-to-action">
          <div className="container">
            <img src="../../public/assets/imgs/b1.png" alt="" />
            <div className="rectangle-cta">
              <div className="row">
                <div className="col-sm-3 nopaddingleft">
                  <h4 className="call-to-tit">Latest News</h4>
                </div>
                <div className="col-sm-6 for-center">
                  <p className="call-to-p">
                    Read To Earn : Read Super Vet Comics To earn rewards !
                  </p>
                </div>
                <div className="col-sm-3 for-center"></div>
              </div>
            </div>
          </div>
        </div>

        <Relatedposts></Relatedposts>
        <div className="join-us-metamsk-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 nopaddingright">
                <img className="join-img" src={joinimg} />
              </div>
              <div className="col-sm-5 nopaddingleft">
                <div className="text-to-align-middle forbg-overlay">
                  <div className="text-wrapper-r">
                    <h3 className="join-us-now">JOIN US</h3>
                    <p className="join-text">
                      READ SUPER VET COMICS AND EARN REWARD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Abilities</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>

        <div className="abilities">
          <div className="row">
            <div className="col-sm-12">
              <div className="for-flexing">
                <div className="text-wrapping">
                  <h3 className="abilities-tit">Super hearing</h3>
                  <p className="abilities-txt">
                    Super Vet Is a Metaverse Concept game where the superheroes
                    rescue the animals with their unique super power given by
                    anonymous. Bruce is one of them his super ability is SUPER
                    HEARING. Bruce here voices of different kind of animals and
                    reach them to rescue them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Enemies</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>
        <div id="Enimiescharacter" className="comics-section">
          <div className="container-xxl">
            <div className="row">
              <div className="comics-wrapper">
                <div className="single-character ">
                  <Link to="/characters" className="single-links">
                    <div className="single-character-wrapper">
                      <img
                        className="single-comic-img"
                        src={Charactenemy}
                        alt=""
                      />
                    </div>
                    <div className="for-border-anime"></div>
                    <div className="single-character-text-wrapper">
                      <h3 className="single-title">BOGGY</h3>
                      <div className="nameandprice">
                        <div className="name">Super Villain</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="single-character ">
                  <div className="who-is-next">
                    <div className="asd">
                      <img
                        className="single-comic-img"
                        src={iconquestion}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="single-character ">
                  <div className="who-is-next">
                    <div className="asd">
                      <img
                        className="single-comic-img"
                        src={iconquestion}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="single-character ">
                  <div className="who-is-next">
                    <div className="asd">
                      <img
                        className="single-comic-img"
                        src={iconquestion}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="single-character ">
                  <div className="who-is-next">
                    <div className="asd">
                      <img
                        className="single-comic-img"
                        src={iconquestion}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="biosection">
          <div className="biosection-txt-btn">
            <h3 className="biosection-tit">
              “END YOUR ASSAULT ON those animals. NEVER do this again. DO IT AND
              I'LL BREAK your neck.”
            </h3>
            <button className="read-bio">Read Bio</button>
          </div>
        </div>

        <div className="spacer-padding"></div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">
                  super Comics
                </h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>

        <div className="morebruce">
          <div className="comics-wrapper">
            <div className="single-comic ">
              <Link to="/Bruce">
                <img className="single-comic-img" src={Brucevol1} alt="" />
                <h3 className="single-title">DR.BRUCE:</h3>
                <div className="nameandprice">
                  <div className="name">dR.BRUCE VOL 1 PILOT EPISODE</div>
                </div>
              </Link>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={Brucevol2} alt="" />
              <h3 className="single-title">DR.BRUCE:</h3>
              <div className="nameandprice">
                <div className="name">DR.BRUCE Vol 2</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>

            <div className="single-comic ">
              <img className="single-comic-img" src={commingsoon} alt="" />
              <h3 className="single-title">Coming soon...</h3>
              <div className="nameandprice">
                <div className="name">Super Vet</div>
              </div>
            </div>
          </div>
        </div>

        <div id="biosection">
          <div className="biosection-txt-btn">
            <h3 className="biosection-tit">
              “END YOUR ASSAULT ON those animals. NEVER do this again. DO IT AND
              I'LL BREAK your neck.”
            </h3>
            <button className="read-bio">Read Bio</button>
          </div>
        </div>

        <div className="spacer-padding"></div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="call-to-action">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">
                  super Comics
                </h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>

        <div className="comics-section">
          <div className="container-xxl">
            <div className="row">
              <div className="comics-wrapper">
                <div className="single-comic video">
                  <div className="image-overlay-videocomicsmain"> </div>
                  <img
                    className="single-comic-img videopage"
                    src={Brucevol1}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />

                  <h3 className="single-title ">
                    DR.BRUCE VOL 1 pilot episode
                  </h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>

                <div className="single-comic video">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={Brucevol2}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />

                  <h3 className="single-title ">DR.Nina VOL 1</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>

                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>

                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>

                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>

                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
                <div className="single-comic video comming-soon-videos">
                  <div className="image-overlay-videocomicsmain"></div>
                  <img
                    className="single-comic-img videopage"
                    src={commingsoon}
                    alt=""
                  />
                  <img className="video-iconz" src={videoico} alt="" />
                  <h3 className="single-title ">Coming soon</h3>
                  <div className="nameandprice ">
                    <div className="name ">Super Vet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="biosection">
          <div className="biosection-txt-btn">
            <h3 className="biosection-tit">
              “END YOUR ASSAULT ON those animals. NEVER do this again. DO IT AND
              I'LL BREAK your neck.”
            </h3>
            <button className="read-bio">Read Bio</button>
          </div>
        </div>

        <div className="spacer-padding"></div>
      </TabPanel>
    </Box>
  );
}
