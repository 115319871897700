import React, { Component } from "react";
import Homebody from "./homebody";
import Footer from "./Footer";
import Header from "./Header";
import Slider1c from "./Slider1-copy";
export default class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <Slider1c />
        <Homebody />
        <Footer />
      </>
    );
  }
}
