import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import eventbanner from "../../assets/imgs/banners/eventpage.png"
import EventTabs from "./EventTabs";

export default class Events extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="eventpagebanner">
                    <div className="container">
                        <div className="text-wrapper">
                            <h1 className="event-main-tit"><span>SUPER</span> COMPETITION</h1>
                        </div>
                    </div>
                    <img className="slide1 img-fluid" src={eventbanner} alt="" />

                </div>
                <div className="main-bg">
                    <div className="container">
                        <div className="row">
                            <EventTabs></EventTabs>

                        </div>
                    </div>
                </div>


                <Footer />
            </>
        );
    }
}
