import React, { Component } from "react";
import Footer from "./Footer";
import bannerprofile from "../assets/imgs/banners/profile-banner.png";
import Brucevol1 from "../assets/imgs/bruce.png";
import frontgold from "../assets/imgs/FRONTGOLD.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import Psycho from "../assets/imgs/psycho.png";
import Vision from "../assets/imgs/mr-vision.jpg";
import Gibran from "../assets/imgs/dr-jibran.jpg";
import Ivy from "../assets/imgs/ivy.jpg";
import Drx from "../assets/imgs/dr-x.jpg";
import { Link } from "react-router-dom";
import videoico from "../assets/imgs/videoicon.png";
import profileimg from "../assets/imgs/profile/profile-img.png"
import profileimgicon from "../assets/imgs/profile/icon.png"
import cameraico from "../assets/imgs/profile/camera.png"
import Headerprofile from "./Headerprofile";
export default class Userprofile extends Component {
  render() {
    return (
      <>
        <Headerprofile />
        <div className="main-bg profile-page">
          <div className="main-bg-overlay">
            <div className="profile-banner-section">
              <img style={{ width: "100%" }} src={bannerprofile} alt="" />
              <div className="anthor-div-for-center text-center">
                <div className="camera-ico-wrapper">
                  <img className="cameraico" src={cameraico} alt="" /> Edit Cover Photo

                </div>
              </div>

            </div>
            <div className="container">
              <div className="row">
                <div className="profile-img-section">
                  <div className="imgswrap">
                    <img className="profile-img" src={profileimg} alt="" />
                    <img className="icon-photo" src={profileimgicon} alt="" />
                  </div>
                  <div className="text-wrapper-profile-section">
                    <h3 className="pro-title">
                      Wassam Qazi
                    </h3>
                    <p className="joining-date">Joined May 25 2022</p>
                  </div>


                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">My comics</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="comics-section">
              <div className="container-xxl">
                <div className="row">
                  <div className="comics-wrapper">
                    <div className="single-comic">
                      <Link to="/Bruce" className="single-links">
                        <p className="new-lable">NEW</p>
                        <img
                          className="single-comic-img"
                          src={Brucevol1}
                          alt=""
                        />
                        <h3 className="single-title">
                          DR.BRUCE VOL 1 PILOT EPISODE
                        </h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                          <div className="price">
                            <img src={frontgold} alt="" />
                            <p className="price-para">
                              300 - <span>Buy</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Nina} alt="" />
                      <h3 className="single-title">
                        Sister Nina VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img
                        className="single-comic-img"
                        src={Steelshot}
                        alt=""
                      />
                      <h3 className="single-title">
                        DR.Steelshot
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Wolf} alt="" />
                      <h3 className="single-title">
                        DR.Wolf VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Psycho} alt="" />
                      <h3 className="single-title">
                        DR.Psycho VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Gibran} alt="" />
                      <h3 className="single-title">
                        DR.Gibran VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Vision} alt="" />
                      <h3 className="single-title">
                        MR.Vision VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Ivy} alt="" />
                      <h3 className="single-title">
                        DR.IVY VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Drx} alt="" />
                      <h3 className="single-title">DR.X VOL 1 PILOT EPISODE</h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img
                        className="single-comic-img"
                        src={Brucevol1}
                        alt=""
                      />
                      <h3 className="single-title">
                        DR.BRUCE VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">My Video comics</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comics-section">
              <div className="container-xxl">
                <div className="row">
                  <div className="comics-wrapper">
                    <div className="single-comic video">
                      <div className="image-overlay-videocomicsmain"> </div>
                      <img
                        className="single-comic-img videopage"
                        src={Brucevol1}
                        alt=""
                      />
                      <img className="video-iconz" src={videoico} alt="" />

                      <h3 className="single-title ">
                        DR.BRUCE VOL 1 pilot episode
                      </h3>
                      <div className="nameandprice ">
                        <div className="name ">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-comic video">
                      <div className="image-overlay-videocomicsmain"></div>
                      <img
                        className="single-comic-img videopage"
                        src={Nina}
                        alt=""
                      />
                      <img className="video-iconz" src={videoico} alt="" />

                      <h3 className="single-title ">DR.Nina VOL 1</h3>
                      <div className="nameandprice ">
                        <div className="name ">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-comic video">
                      <div className="image-overlay-videocomicsmain"> </div>
                      <img
                        className="single-comic-img videopage"
                        src={Steelshot}
                        alt=""
                      />
                      <img className="video-iconz" src={videoico} alt="" />

                      <h3 className="single-title ">DR.Steelshot VOL 1</h3>
                      <div className="nameandprice ">
                        <div className="name ">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic video"></div>
                    <div className="single-comic video"></div>

                    <div className="single-comic video"></div>
                    <div className="single-comic video"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">My rentals</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comics-section">
              <div className="container-xxl">
                <div className="row">
                  <div className="comics-wrapper">
                    <div className="single-comic video">
                      <div className="image-overlay-videocomicsmain"> </div>
                      <img
                        className="single-comic-img videopage"
                        src={Brucevol1}
                        alt=""
                      />
                      <img className="video-iconz" src={videoico} alt="" />

                      <h3 className="single-title ">
                        DR.BRUCE VOL 1 pilot episode
                      </h3>
                      <div className="nameandprice ">
                        <div className="name ">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-comic video">
                      <div className="image-overlay-videocomicsmain"></div>
                      <img
                        className="single-comic-img videopage"
                        src={Nina}
                        alt=""
                      />
                      <img className="video-iconz" src={videoico} alt="" />

                      <h3 className="single-title ">DR.Nina VOL 1</h3>
                      <div className="nameandprice ">
                        <div className="name ">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">My BORROWS</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="comics-section" style={{ paddingBottom: "80px" }}>
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Brucevol1}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">
                      DR.BRUCE VOL 1 pilot episode
                    </h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"></div>
                    <img
                      className="single-comic-img videopage"
                      src={Nina}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Nina VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
