import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/imgs/videospage/b1.png";
import slide2 from "../../assets/imgs/videospage/b2.png";
import slide3 from "../../assets/imgs/videospage/b3.png";
import videoicon from "../../assets/imgs/videoiconbig.png";

export default class Videocomicsslider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      Arrows: true,
      autoplay: true,
      autoplaySpeed: 3000

    };
    return (
      <div className="main-slider-slider1">
        <Slider {...settings}>
          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">Super Comics</h3>
                <h1 className="main-tit">let’s watch it superverse</h1>
              </div>
              <img className="video-icons" src={videoicon} alt="" />
            </div>
            <img className="slide1" src={slide1} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">Super Comics</h3>
                <h1 className="main-tit">the boggy VEt rises</h1>
              </div>
              <img className="video-icons vico2" src={videoicon} alt="" />
            </div>
            <img className="slide1" src={slide2} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">Super Comics</h3>
                <h1 className="main-tit">About Metaverse</h1>
              </div>
              <img className="video-icons vico3" src={videoicon} alt="" />
            </div>
            <img className="slide1" src={slide3} alt="" />
          </div>
        </Slider>
      </div>
    );
  }
}
