import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Relatedposts from "../relatedposts";
import Characterpageslider from "../sliders/Characterpageslider";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function imgHander() {
  var wolf = document.getElementById("wolf");
  wolf.src = "../../../assets/imgs/events/wolfhover.png";
}
function gibran() {
  var gibran = document.getElementById("gibran");
  gibran.src = "../../../assets/imgs/events/gibranhover.png";
}
function bruce() {
  var bruce = document.getElementById("bruce");
  bruce.src = "../../../assets/imgs/events/brucehover.png";
}

function ivy() {
  var ivy = document.getElementById("ivy");
  ivy.src = "../../../assets/imgs/events/ivyhover.png";
}
function steelshot() {
  var steelshot = document.getElementById("steelshot");
  steelshot.src = "../../../assets/imgs/events/steelshothover.png";
}
function psycho() {
  var psycho = document.getElementById("psycho");
  psycho.src = "../../../assets/imgs/events/Psychohover.png";
}
function imgHanderleave() {
  var wolf = document.getElementById("wolf");
  wolf.src = "../../../assets/imgs/events/wolf.png";
  var gibran = document.getElementById("gibran");
  gibran.src = "../../../assets/imgs/events/gibran.png";
  var bruce = document.getElementById("bruce");
  bruce.src = "../../../assets/imgs/events/bruce.png";
  var ivy = document.getElementById("ivy");
  ivy.src = "../../../assets/imgs/events/ivy.png";
  var steelshot = document.getElementById("steelshot");
  steelshot.src = "../../../assets/imgs/events/steelshot.png";
  var psycho = document.getElementById("psycho");
  psycho.src = "../../../assets/imgs/events/psycho.png";
}
function activetab() {
  var psycho = document.getElementById("activetab");
  psycho.src = "../../../assets/imgs/active-tab-hover.png";
}
function activetabhover() {
  var psycho = document.getElementById("activetab");
  psycho.src = "../../../assets/imgs/active-tab.png";
}

//ended
function endedimgHander() {
  var wolf = document.getElementById("endedwolf");
  wolf.src = "../../../assets/imgs/events/ended/wolfhover.png";
}
function endedgibran() {
  var gibran = document.getElementById("endedgibran");
  gibran.src = "../../../assets/imgs/events/ended/gibranhover.png";
}
function endedbruce() {
  var bruce = document.getElementById("endedbruce");
  bruce.src = "../../../assets/imgs/events/ended/brucehover.png";
}

function endedivy() {
  var ivy = document.getElementById("endedivy");
  ivy.src = "../../../assets/imgs/events/ended/ivyhover.png";
}
function endedsteelshot() {
  var steelshot = document.getElementById("endedsteelshot");
  steelshot.src = "../../../assets/imgs/events/ended/steelshothover.png";
}
function endedpsycho() {
  var psycho = document.getElementById("endedpsycho");
  psycho.src = "../../../assets/imgs/events/ended/Psychohover.png";
}
function endedimgHanderleave() {
  var wolf = document.getElementById("endedwolf");
  wolf.src = "../../../assets/imgs/events/ended/wolf.png";
  var gibran = document.getElementById("endedgibran");
  gibran.src = "../../../assets/imgs/events/ended/gibran.png";
  var bruce = document.getElementById("endedbruce");
  bruce.src = "../../../assets/imgs/events/ended/bruce.png";
  var ivy = document.getElementById("endedivy");
  ivy.src = "../../../assets/imgs/events/ended/ivy.png";
  var steelshot = document.getElementById("endedsteelshot");
  steelshot.src = "../../../assets/imgs/events/ended/steelshot.png";
  var psycho = document.getElementById("endedpsycho");
  psycho.src = "../../../assets/imgs/events/ended/psycho.png";
}

export default function EventTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Upcoming" {...a11yProps(0)} />
          <Tab label="Active" {...a11yProps(1)} />
          <Tab label="Ended" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="call-to-action upcoming">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Upcoming</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>
        <div className="cards-section">
          <div className="row e-first-row">
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="wolf"
                  className="img-fluid wolf"
                  src="../../../assets/imgs/events/wolf.png"
                  onMouseEnter={imgHander}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="gibran"
                  className="img-fluid wolf"
                  src="../../../assets/imgs/events/gibran.png"
                  onMouseEnter={gibran}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="bruce"
                  className="img-fluid"
                  src="../../../assets/imgs/events/bruce.png"
                  onMouseEnter={bruce}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
          </div>
          <div className="row e-second-row">
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="ivy"
                  className="img-fluid"
                  src="../../../assets/imgs/events/ivy.png"
                  onMouseEnter={ivy}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="steelshot"
                  className="img-fluid"
                  src="../../../assets/imgs/events/steelshot.png"
                  onMouseEnter={steelshot}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <h3 className="upcoming-date">August-10-2022 </h3>
                <img
                  id="psycho"
                  className="img-fluid"
                  src="../../../assets/imgs/events/psycho.png"
                  onMouseEnter={psycho}
                  onMouseLeave={imgHanderleave}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="spacer-padding"></div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="call-to-action active">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Active</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>
        <div className="active-tab-section">
          <div className="row">
            <div className="active-tab-bg">
              <Link to="/quiz">
                <img
                  id="activetab"
                  className="img-fluid"
                  src="../../../assets/imgs/active-tab.png"
                  onMouseEnter={activetab}
                  onMouseLeave={activetabhover}
                />
              </Link>
              <div className="col-sm-4 col-6">
                <div className="text-wrapping" onMouseEnter={activetab}>
                  <h3 className="activetabtit">
                    <span>TOTAL PLAYERS :</span> 1536
                  </h3>
                  <h3 className="activetabtit">
                    <span>REWARDS WIN : :</span> 200
                  </h3>

                  <h3 className="activetabtit">
                    <span>PLAYER LOSS :</span> 13556
                  </h3>

                  <h3 className="activetabtit">
                    <span>START:</span> 10-August-22 10PM
                  </h3>
                  <h3 className="activetabtit">
                    <span>ENDS :</span> 15-August-22 10PM
                  </h3>
                </div>
              </div>
              <div className="col-sm-8"></div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="call-to-action ended">
          <div className="rectangle-cta">
            <div className="row">
              <div className="col-sm-3 ">
                <h4 className="call-to-tit featured-characters">Ended</h4>
              </div>
              <div className="col-sm-6 for-center">
                <p className="call-to-p">
                  Read To Earn : Read Super Vet Comics To earn rewards !
                </p>
              </div>
              <div className="col-sm-3 for-center"></div>
            </div>
          </div>
        </div>

        <div className="cards-section">
          <div className="row e-first-row">
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedwolf"
                    className="img-fluid image-for-hover"
                    src="../../../assets/imgs/events/ended/wolf.png"
                    onMouseEnter={endedimgHander}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedgibran"
                    className="img-fluid image-for-hover"
                    src="../../../assets/imgs/events/ended/gibran.png"
                    onMouseEnter={endedgibran}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedbruce"
                    className="img-fluid"
                    src="../../../assets/imgs/events/ended/bruce.png"
                    onMouseEnter={endedbruce}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="row e-second-row">
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedivy"
                    className="img-fluid"
                    src="../../../assets/imgs/events/ended/ivy.png"
                    onMouseEnter={endedivy}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedsteelshot"
                    className="img-fluid"
                    src="../../../assets/imgs/events/ended/steelshot.png"
                    onMouseEnter={endedsteelshot}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="img-card-bg-event">
                <Link to="/historyleaderboard">
                  <img
                    id="endedpsycho"
                    className="img-fluid"
                    src="../../../assets/imgs/events/ended/psycho.png"
                    onMouseEnter={endedpsycho}
                    onMouseLeave={endedimgHanderleave}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </Box>
  );
}
