import React, { Component } from "react";
import { Link } from "react-router-dom";
import footlogo from "../assets/imgs/foot-logo.png";
import icon1 from "../assets/imgs/icons/icon-dd.png";
import icon2 from "../assets/imgs/icons/icon-fb.png";
import icon3 from "../assets/imgs/icons/icon-ig.png";
import icon4 from "../assets/imgs/icons/icon-tlg.png";
import icon5 from "../assets/imgs/icons/icon-twt.png";
import icon6 from "../assets/imgs/icons/icon-ytb.png";

export default class Footer extends Component {
  render() {
    return (
      <div className="main-footer">
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-3">
                <Link to="/" className="footer-logo-link">
                  <img className="foot-logo" src={footlogo} alt="" />
                </Link>
              </div>
              <div className="col-sm-2 col-6 ">
                <h3 className="footer-tit">Learn More</h3>
                <ul>
                  <li>
                    <a className="footer-links" href="#">
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-links"
                      href="https://supervet.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Gameplay
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-links"
                      href="https://supervet.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a className="footer-links"> Superverse</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 col-6 ">
                <h3 className="footer-tit">Marketplace</h3>
                <ul>
                  <li>
                    <a
                      className="footer-links"
                      href="https://market.supervet.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Characters
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-links"
                      href="https://market.supervet.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      In-Game Assets
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer-links"
                      href="https://market.supervet.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Buy/Sell
                    </a>
                  </li>
                  <li>
                    <a className="footer-links" href="#">
                      NFT Staking
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-sm-2 ">
                <div className="quicklinks">
                  <h3 className="footer-tit">Quick Links</h3>
                  <ul>
                    <li>
                      <a
                        className="footer-links"
                        href="https://super-vet.gitbook.io/super-vet-white-paper"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-links"
                        href="https://drive.google.com/file/d/1ZkrHMFSOC-hUIZk1ACVHV48LCtrwb9b-/view"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pitch Deck
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-links"
                        href="https://market.supervet.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Marketplace
                      </a>
                    </li>

                    <li>
                      <a className="footer-links" href="#">
                        Smart Contract
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-3">
                <h3 className="footer-tit follow-us">Follow Us</h3>
                <div className="social-links follow-us">
                  <a
                    href="https://twitter.com/SuperVet_io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon5} alt="" />
                  </a>
                  <a
                    href="https://medium.com/@SuperVet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon2} alt="" />
                  </a>
                  <a
                    href="https://t.me/SuperVet_Ann"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon4} alt="" />
                  </a>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCakQ5vkIGc5q1toCA7nO5sw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon6} alt="" />
                  </a>
                  <a
                    href="https://vt.tiktok.com/ZGJUqfxMd/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon3} alt="" />
                  </a>
                  <a
                    href="https://discord.com/invite/J3rywtphjz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={icon1} alt="" />
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-sm-12">
              <ul className="below-footer-links">
                      <li><a className="footer-links" href="#"> Terms of Use</a></li>
                      <li><a className="footer-links" href="#">Privacy Ploicy</a></li>
                      <li><a className="footer-links" href="#">Your Supervet Privicy Rights</a></li>
                      <li><a className="footer-links" href="#">License Agreement</a></li>
                      <li><a className="footer-links" href="#">Interest-Based Ads</a></li>
                  </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
