import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Brucevol1 from "../assets/imgs/bruce.png";
import commingsoon from "../assets/imgs/commingsoon.png";
import drbrucesinglecover from "../assets/imgs/drbrucesinglecover.png";
import sisterNina from "../assets/imgs/nina.png";
import pdf from "../assets/comics/Sister Nina Volume 1.pdf"

export default class singleComicsNina extends Component {



  render() {
    return (
      <>
        <Header />
        <div className="webbackground">
          <div className="web-bg-overlay single-comic-whole">
            <div className="single-comics">
              <div className="overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-3 ">
                      <div className="single-img-wrapper">
                        <img
                          className="single-cover"
                          src={sisterNina}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-8 ">
                      <div className="single-right-side-wrap">
                        <h3 className="single-comic-tit">
                          Sister Nina : Super Vet
                        </h3>
                        <p className="text-comics">May 22,2023</p>
                        <h3 className="published">Published:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                        <h3 className="published">Description:</h3>

                        <p className="text-comics description">

                          Sister Nina, an intelligent and award-winner obstetrician nurse, faced expulsion from her job due to false allegations of negligence. The depressed soul was about to commit suicide but life has got different plans for her.
                        </p>
                        <div className="buttons">
                          <div className="thetwobtns">
                            <div className="readmore-wrap">
                              <a
                                href="https://drive.google.com/file/d/13C100rxzWoK8SAeOctQn6BDoM3PTWmJ_/view?usp=sharing"
                                target="_blank"
                                className="single-comics-btns svet-btn"
                              >
                                Read Now
                              </a>
                            </div>
                            <div className="freewrap">
                              <a
                                href="https://drive.google.com/file/d/13C100rxzWoK8SAeOctQn6BDoM3PTWmJ_/view?usp=sharing"
                                target="_blank"
                                className="single-comics-btns buynow"
                              >
                                Free
                              </a>
                            </div>
                          </div>
                          <a className="single-comics-btns onrent">
                            Not available on rent
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">More About Nina</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="morebruce">
              <div className="container">
                <div className="row">
                  <div className="comics-wrapper noborder nopadding">
                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={sisterNina}
                        alt=""
                      />
                      <h3 className="single-title">Sister Nina vol 1 </h3>
                      <div className="nameandprice">
                        <div className="name">Super vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">recommended vol </h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center">
                      <a className="call-to-btn" href="Join-now">
                        Join Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="morebruce">
              <div className="container">
                <div className="row">
                  <div className="comics-wrapper noborder  ">
                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={sisterNina}
                        alt=""
                      />
                      <h3 className="single-title">Sister Nina vol 1</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>

                    <div className="single-comic ">
                      <img
                        className="single-comic-img"
                        src={commingsoon}
                        alt=""
                      />
                      <h3 className="single-title">Coming Soon</h3>
                      <div className="nameandprice">
                        <div className="name">Super Vet</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
