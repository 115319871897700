import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../logo.png";
import cbtn from "../assets/imgs/c-button.png";
import comics from "../assets/imgs/comic-logo.png";
import Searchico from "../assets/imgs/icons/search-ico.png";
import React, { useState } from "react";
import { useEffect } from "react";
import web3 from "../connection/web3";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hamburger from "../assets/imgs/hamburger.png";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

function Headerprofile(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [networkError, setNetworkError] = useState(undefined);
  const [userAdd, setUserAdd] = useState("");
  const [walletConnected, setWalletConnected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [tooltipText, settooltipText] = useState("");
  const HARDHAT_NETWORK_ID = "97";
  //const HARDHAT_NETWORK_ID = "56";
  const history = useHistory();
  // const alert = useAlert();

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const getCurrentWalletConnected = async () => {
    if (web3) {
      try {
        web3.eth.net.getId().then((netId) => {
          if (netId.toString() === HARDHAT_NETWORK_ID) {
            web3.eth.requestAccounts().then((addressArray) => {
              if (addressArray.length > 0) {
                setSelectedAddress(addressArray[0]);
                localStorage.setItem("address", addressArray[0]);
                if (!localStorage.getItem("signer") === null) {
                  //getSignIn();
                } else {
                  if (!(localStorage.getItem("signer") === addressArray[0])) {
                    //getSignIn();
                  }
                }
              } else {
                setSelectedAddress("");
                localStorage.setItem("address", "");
              }
            });
            // const addressArray = web3.eth.getAccounts();
          } else {
            toast("Please connect Metamask to BSC Testnet");
            setSelectedAddress("");
            localStorage.setItem("address", "");
          }
        });
      } catch (err) {
        setSelectedAddress("");
        localStorage.setItem("address", "");
      }
    } else {
      setSelectedAddress("");
      localStorage.setItem("address", "");
    }
  };

  useEffect(async () => {
    // settooltipText("Copy");
    await getCurrentWalletConnected();
    //setSelectedAddress(address);
    addWalletListener();
    chainListener();
  }, []);

  const signMessage = async ({ message }) => {
    const address = (await web3.eth.requestAccounts())[0];
    const signature = await web3.eth.personal.sign(message, address);
    return {
      signature,
      address,
      message,
    };
  };

  // const getSignIn = async () => {
  //   try {
  //     const sig = await signMessage({
  //       message: "Super Vet",
  //     });
  //     if (sig) {
  //       // await saveTodo(sig);
  //       // await timeout(8000);
  //       localStorage.setItem("signer", sig.address);
  //       window.location.reload();
  //       // alert.show("Your SignIn Hash: " + sig.signature);
  //     }
  //   } catch (err) {
  //     toast("Please Sign In To Continue......");
  //     setSelectedAddress("");
  //     localStorage.setItem("address", "");
  //     await timeout(2000);
  //     window.location.reload();
  //   }
  // };

  const connectWalletPressed = async () => {
    // console.log("inside connectWalletPressed");
    if (window.ethereum) {
      web3.eth.net.getId().then((netId) => {
        if (netId.toString() === HARDHAT_NETWORK_ID) {
          console.log("connectWalletPressed");
          const walletResponse = connectWallet();
          setSelectedAddress(walletResponse.address);
          localStorage.setItem("address", walletResponse.address);
          // getSignIn();
        } else {
          toast("Please connect Metamask to BSC Testnet");
        }
      });
    } else {
      toast(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
        };
      }
    } else {
      return {
        address: "",
      };
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setSelectedAddress(accounts[0]);
          localStorage.setItem("address", accounts[0]);
          console.log("addWalletListener");
          // history.push({
          //   pathname: `/`
          // });
          window.location.reload();
        } else {
          setSelectedAddress("");
          localStorage.setItem("address", "");
          toast("Connect to Metamask using the top right button.");
        }
      });
    } else {
      toast(
        "You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }

  function chainListener() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        console.log(_chainId);
        if (_chainId !== HARDHAT_NETWORK_ID) {
          localStorage.setItem("address", "");
          window.location.reload();
        }
        // if (_chainId === HARDHAT_NETWORK_ID) {
        //   console.log('Hello BC');
        //   window.location.reload();
        // }
      });
    } else {
      toast(
        "You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }
  function handlenavigation() {
    var objj = document.getElementById("forham");
    console.log("I am clicked");
    objj.classList.toggle("mystyle");
  }

  return (
    <div className="App">
      <header className="header-main">
        <div className="header-bg profile-page">
          <div className="container">
            <div className="row">
              <div className="col-sm-1 both-c">
                <div className="logo-c">
                  <Link to="/">
                    <img className="logo" src={logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="comic-logo-wrapper">
                  <Link to="/">
                    <img className="comics-logo" src={comics} alt="" />
                  </Link>
                </div>

                <img
                  className="hamburger"
                  src={hamburger}
                  alt=""
                  onClick={() => handlenavigation()}
                />
              </div>
              <div className="col-sm-8">
                <ul id="forham" className="nav main-navigation">
                  <li>
                    <Link className="nav-items" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile" className="nav-items">
                      My Comics
                    </Link>
                  </li>
                  <li>
                    <Link to="/mytanents" className="nav-items">
                      My Tanents
                    </Link>
                  </li>
                  <li>
                    <a className="nav-items" href="#">
                      Svet Rewards
                    </a>
                  </li>

                  <li>
                    <a
                      className="nav-items"
                      href="#"

                    >

                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Headerprofile;
