import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import arrowimg from "../../assets/imgs/news/arrow.png"
import post1 from "../../assets/imgs/news/post1.png"
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
import { Link } from "react-router-dom";
import img2 from "../../assets/imgs/news/post12.png"

export default class Singlepost1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="backtocomics">
                    <div className="container">
                        <div className="row">
                            <Link to="/">
                                <div className="btc-wrap">
                                    <img className="arrow-blog" src={arrowimg} alt="" />
                                    <h3 className="backtocomics">Back to Comics</h3>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>


                <div className="main-bg blog-single-post">
                    <div className="main-bg-overlay">
                        <div className="container">
                            <div className="row">
                                <h3 className="category">Comics</h3>
                                <p className="published-date">
                                    Published June 13, 2022
                                </p>

                                <h1 className="single-post-title">When 'What If?’ Comes True</h1>
                                <p className="short-details">
                                    Take a look back at the ‘What If?’ comic stories that came
                                    to pass in the main SuperVerse!
                                </p>

                            </div>
                        </div>


                        <div className="author-container">
                            <div className="container">
                                <div className="row">
                                    <p className="author">By Super Vet</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">



                                <p className="paragraph">
                                    A simple and sweet kid who once received a beautiful gift from his father, a cat. He started nurturing it with his heart for
                                    days. But, one day, when he woke up, he found nowhere his cat in his home. Following the footsteps, he went to a well where
                                    it was bleeding dead. He buried it with a very heavy heart and went shattered to the church to pray. Having low blood pressure,
                                    he slept there and woke with blessed superpowers of rescuing pets. Super Vet— Dr. Bruce got his animal-rescue mission for life
                                    forever.
                                </p>


                                {/* 
                                <h3 className="second-tit">
                                    WHAT IF DR.BRUCE HAD JOINED THE BOGGY ?
                                </h3> */}

                                <img style={{ paddingTop: '30px' }} className="img-fluid" src={post1} alt="" />
                                <p className="details">
                                    <b>  Powers: </b>
                                    Rescue pet animals like cats and dogs with his super sensing powers


                                </p>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                {/* <h3 className="second-tit">
                                    WHAT IF DR.BRUCE HAD JOINED THE BOGGY ? (WHAT IF? #1)
                                </h3>
                                <img className="img-fluid" src={img2} alt="" />
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p> */}
                            </div>
                        </div>



                        <div className="container">
                            <div className="row">
                                {/* Related */}
                                <div className="call-to-action">

                                    <img src="../../public/assets/imgs/b1.png" alt="" />
                                    <div className="rectangle-cta">
                                        <div className="row">
                                            <div className="col-sm-3 ">
                                                <h4 className="call-to-tit">Related</h4>
                                            </div>
                                            <div className="col-sm-6 for-center">
                                                <p className="call-to-p">
                                                    Read To Earn : Read Super Vet Comics To earn rewards !
                                                </p>
                                            </div>
                                            <div className="col-sm-3 for-center"></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="character-spotlight">

                                    <div className="row">


                                        <div className="col-sm-4">
                                            <Link to="/post4">
                                                <div className="spot-wrapper">
                                                    <img src={spot2} alt="" />
                                                    <h3 className="spot-tit1">TRAILERS & EXTRAS</h3>
                                                    <h3 className="spot-tit2">Who’s Gona Help Under Sea</h3>
                                                </div>
                                            </Link>

                                        </div>

                                        <div className="col-sm-4">
                                            <Link to="/post2">
                                                <div className="spot-wrapper">
                                                    <img src={spot3} alt="" />
                                                    <h3 className="spot-tit1">SUPER COMICS</h3>
                                                    <h3 className="spot-tit2">First Appearances: Vision</h3>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-sm-4">
                                            <Link to="/post1">
                                                <div className="spot-wrapper">
                                                    <img src={spot4} alt="" />
                                                    <h3 className="spot-tit1">Amazing Comics</h3>
                                                    <h3 className="spot-tit2">Super Heroes</h3>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
