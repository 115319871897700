import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Brucevol1 from "../assets/imgs/bruce.png";
import frontgold from "../assets/imgs/FRONTGOLD.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import videoico from "../assets/imgs/videoicon.png";
import Psycho from "../assets/imgs/psycho.png";

export default class Videoslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      Arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,

          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,

          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

          },
        },
      ],
    };
    return (
      <>
        <Slider {...settings}>
          <div>
            <div className="single-comic video">
              <div className="image-overlay">
                <img
                  className="single-comic-img video"
                  src={Brucevol1}
                  alt=""
                />
                <img className="video-icon" src={videoico} alt="" />
              </div>

              <h3 className="single-title ">DR.BRUCE VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice ">
                <div className="name ">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    Free
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic video">
              <div className="image-overlay">
                <img className="single-comic-img video" src={Nina} alt="" />
                <img className="video-icon" src={videoico} alt="" />
              </div>

              <h3 className="single-title ">DR.Nina VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice ">
                <div className="name ">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    Free
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic video">
              <div className="image-overlay">
                <img
                  className="single-comic-img video"
                  src={Steelshot}
                  alt=""
                />
                <img className="video-icon" src={videoico} alt="" />
              </div>

              <h3 className="single-title ">
                DR.Steelshot VOL 1 PILOT EPISODE
              </h3>
              <div className="nameandprice ">
                <div className="name ">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic video">
              <div className="image-overlay">
                <img className="single-comic-img video" src={Wolf} alt="" />
                <img className="video-icon" src={videoico} alt="" />
              </div>

              <h3 className="single-title ">DR.Wolf VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice ">
                <div className="name ">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic video">
              <div className="image-overlay">
                <img className="single-comic-img video" src={Psycho} alt="" />
                <img className="video-icon" src={videoico} alt="" />
              </div>

              <h3 className="single-title ">DR.Psycho VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice ">
                <div className="name ">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}
