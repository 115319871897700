import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Brucevol1 from "../../assets/imgs/bruce.png";
import frontgold from "../../assets/imgs/FRONTGOLD.png";
import videoico from "../../assets/imgs/videoicon.png";
import Nina from "../../assets/imgs/nina.png";
import Steelshot from "../../assets/imgs/steelshot.png";
import Wolf from "../../assets/imgs/wolf.png";
import Psycho from "../../assets/imgs/psycho.png";
import Vision from "../../assets/imgs/mr-vision.jpg";
import Ivy from "../../assets/imgs/ivy.jpg";
import Drx from "../../assets/imgs/dr-x.jpg";
import Gibran from "../../assets/imgs/dr-jibran.jpg";
import commingsoon from "../../assets/imgs/commingsoon.png";
import supervideocomicsbanner from "../../assets/imgs/banners/supervideobanner.png"
import { Link } from "react-router-dom";

export default class Supercomics extends Component {
  render() {
    return (
      <>
        <Header />
        <div class="containers">
          <img style={{ width: '100%' }} src={supervideocomicsbanner} alt="Snow" />

          <div class="centered">
            <div className="ch-bann-txt-wrap">
              <h2 className="character-banner-heading">
                <span className="spancoloryellow">SUPER VET </span>
                Super Comics
              </h2>
              <h3 className="character-banner-text">
                We Are Here To Rescue Helpless Animals
              </h3>
            </div></div>
        </div>



        <div className="main-bg">
          <div className="call-to-action">
            <div className="container">
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit featured-characters">
                      Super Comics
                    </h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="comics-section">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-comic">
                    <Link to="/Bruce" className="single-links">
                      <p className="new-lable">NEW</p>
                      <img
                        className="single-comic-img"
                        src={Brucevol1}
                        alt=""
                      />
                      <h3 className="single-title">
                        DR.BRUCE VOL 1 PILOT EPISODE
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            300 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Nina} alt="" />
                    <h3 className="single-title">
                      Sister Nina VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Steelshot} alt="" />
                    <h3 className="single-title">
                      DR.Steelshot VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Wolf} alt="" />
                    <h3 className="single-title">
                      DR.Wolf VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Psycho} alt="" />
                    <h3 className="single-title">
                      DR.Psycho VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Gibran} alt="" />
                    <h3 className="single-title">
                      DR.Gibran VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Vision} alt="" />
                    <h3 className="single-title">
                      MR.Vision VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Ivy} alt="" />
                    <h3 className="single-title">DR.IVY VOL 1 PILOT EPISODE</h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Drx} alt="" />
                    <h3 className="single-title">DR.X VOL 1 PILOT EPISODE</h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic">
                    <p className="new-lable">NEW</p>
                    <img className="single-comic-img" src={Brucevol1} alt="" />
                    <h3 className="single-title">
                      DR.BRUCE VOL 1 PILOT EPISODE
                    </h3>
                    <div className="nameandprice">
                      <div className="name">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-padding"></div>
        </div>

        <Footer />
      </>
    );
  }
}
