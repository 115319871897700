import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import arrowimg from "../../assets/imgs/news/arrow.png"
import letsseevision from "../../assets/imgs/news/letsseevision.png"
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
// import drbrucesinglecover from "../../assets/imgs/drbrucesinglecover.png";
import visionf from "../../assets/imgs/news/visionf.png"

import { Link } from "react-router-dom";
import img2 from "../../assets/imgs/news/post12.png"

export default class Singlepost2 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="backtocomics">
                    <div className="container">
                        <div className="row">
                            <Link to="/">
                                <div className="btc-wrap">
                                    <img className="arrow-blog" src={arrowimg} alt="" />
                                    <h3 className="backtocomics">Back to Comics</h3>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>


                <div className="main-bg blog-single-post">
                    <div className="main-bg-overlay">
                        <div className="container">
                            <div className="row">
                                <h3 className="category">Comics</h3>
                                <p className="published-date">
                                    Published June 13, 2022
                                </p>

                                <h1 className="single-post-title">First Appearances: Vision</h1>
                                <p className="short-details">
                                    Mr. Vision
                                </p>

                            </div>
                        </div>


                        <div className="author-container">
                            <div className="container">
                                <div className="row">
                                    <p className="author">By Super Vet</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">



                                <p className="paragraph">
                                    A dying radio-active Trumpeter Swan once bit a picnic enjoying boy. The attack, a few days later, started affecting him, and he felt some feathers on the back of his shoulders. This made him a joke of everyone everywhere. He started fostering many birds in his home and wanted to fly like them and for pursuing his passion, he chose to become Orthonolgist. For researching birds, the adult guy planned to visit the Northern areas where he found the swans again. But, this time, he rescued many birds. His kindness made him get blessed with superpowers of flying, thermal vision, and electric shock-producing hands. The Super Vet— Mr. Vision has a birds-rescue mission for life forever.
                                </p>





                                <img style={{ paddingTop: '20px' }} className="img-fluid" src={letsseevision} alt="" />
                                <p className="short-details" style={{ paddingTop: '20px', fontSize: '20px' }}>
                                    Powers

                                    <ul className="powersofchar">
                                        <li>Can fly high
                                        </li>
                                        <li>Expert in saving birds</li>
                                        <li>Can produce electric shocks by hands</li>
                                        <li>Thermal vision.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>


                        <div className="visionbuynow">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <img
                                            className="img-fluid fav"
                                            src={visionf}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="single-right-side-wrap">
                                            <h3 className="single-comic-tit">
                                                First Appearances: Vision
                                            </h3>
                                            <p className="text-comics">May 25,2022</p>
                                            <h3 className="published">Published:</h3>
                                            <p className="text-comics">Super Vet</p>
                                            <h3 className="published">Writer:</h3>
                                            <p className="text-comics">Maheera Ahmed</p>
                                            <h3 className="published">Cover Artist:</h3>
                                            <p className="text-comics">Sisa Bodani</p>

                                            <div className="buttons">
                                                <div className="forsvets">
                                                    <a
                                                        href="#"

                                                        className="single-comics-btns buynow nomarginleft"
                                                    >
                                                        Buy
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <div className="container">
                            <div className="row">
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p>
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p>
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p>
                            </div>
                        </div> */}



                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
