import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import bruce from "../assets/imgs/Bruceoncharprofile.png";
import Tabss from "./Character/Charprofiletabs";

export default class Characterprofile extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="characterprofilebanner">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="text-to-align-middle">
                  <div className="h2wrapper">
                    <h2 className="character-banner-heading">
                      <span className="spancoloryellow">Dr.</span> Bruce
                    </h2>
                    <h3 className="character-banner-text">
                      Bruce belongs to a well-known reputed rich family.When he
                      was child he had a cat who was very dear to him. One day
                      while he was sleeping, his cat went away to a jungle and
                      fell down an empty well.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <img className="bruceoncharprofile" src={bruce} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="main-bg">
          <div className="charprofiletabs">
            <div className="container">
              <div className="col-sm-12">
                <Tabss />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
