import React, { Component } from "react";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import Headerquiz from "../headerquiz";

var test = 0;

function textreplace() {
  var abc = document.getElementById("bubble-title");
  var bubblebtnobj = document.getElementById("bubblebtnobj");
  var timersec = document.getElementById("timer-sec");
  var letsplatbtn = document.getElementById('letsplatbtn');
  if (test == 0) {
    abc.innerHTML = "this jungle have many rewards for you.";
  } else if (test == 1) {
    abc.innerHTML = "are you ready ! to see my world wahooo! wahoo";
  } else if (test == 2) {
    abc.innerHTML =
      "you have only 3 min t0 beat me. sniffing.... Ahh! You Can’t...";
  } else if (test == 3) {
    abc.innerHTML = "let me see what you got.. wahoo!  wahoo!";
    bubblebtnobj.style.display = "none";
    letsplatbtn.style.display = "block"


  }
  test++;
}
export default class Quizstart extends Component {
  render() {
    return (
      <>
        <Headerquiz />
        <div className="quiz-body">
          <img src="../../../assets/imgs/wolfquiz-bg.png" alt="" className="quizbanner" />
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <button
                  id="bubblebtnobj"
                  className="next-btn"
                  onClick={textreplace}
                >
                  Next
                </button>
                <Link id="letsplatbtn" to="/quizmain">Lets Play</Link>



              </div>
              <div className="col-sm-6">
                <div className="bubble-pop a">
                  <h3 id="bubble-title" className="bubb-tit">
                    wana play... let me show you the wild life. waoooo! waoooo!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
