import React, { Component } from "react";
import Question from "./Question/Question";
import Answer from "./Answer/Answer";
import "./QuizMain.css";
import clockico from "../../assets/imgs/clock.png";
import Headerquiz from "../headerquiz";
import { Link } from "react-router-dom";

export default class Quiz extends Component {
    // initiating the local state
    state = {
        quiestions: {
            1: "How did Dr. Wolf communicate in his childhood with his surroundings?",
            2: "Who brought up Dr. Wolf?",
            3: "Where did Dr. Wolf use to live?",
            4: "From where did Dr. Wolf learn about the medical treatment of animals?",
            5: "What kind of animals does Dr. Wolf rescue and treat?",
            6: "Who named Dr. Wolf as Dr. Wolf?",
            7: "Dr. Wolf cried a lot on:",
            8: "What made him a Super Vet Dr. Wolf?",
            9: "Which animal was used to put Dr. Wolf’s life in danger?",
            10: "The costume that Dr. Wolf wears is of:",

        },
        answers: {
            1: {
                1: "By speaking and writing like humans",
                2: "By growling like wolves “Aaaao”",
                3: "By gestures and drawing",
            },
            2: {
                1: "A pack of wolves",
                2: "A herd of stages",
                3: "A family of veterinarians",
            },
            3: {
                1: "Jungle",
                2: "Rainforest",
                3: "Village",
            },
            4: {
                1: "University",
                2: "Radio",
                3: "Books ",
            },
            5: {
                1: "Only wolves",
                2: "Wild canine animals",
                3: "Herds, flocks, shoals, swarms",
            },
            6: {
                1: "His brain",
                2: "A wolf",
                3: "His dreams",
            },
            7: {
                1: "When he was born",
                2: "When the snake bit him",
                3: "When his father died",
            },
            8: {
                1: "His knowledge and expertise",
                2: "University",
                3: "Super Vet iconic badge",
            },
            9: {
                1: "Stage",
                2: "Wolf",
                3: "Koala ",
            },
            10: {
                1: "Water resistant leather and wool",
                2: "Bullet and scratch proof",
                3: "His wolf’s father peeled-off skin",
            },
        },
        correctAnswers: {
            1: "2",
            2: "1",
            3: "1",
            4: "2",
            5: "2",
            6: "3",
            7: "3",
            8: "3",
            9: "1",
            10: "3",
        },
        correctAnswer: 0,
        clickedAnswer: 0,
        step: 1,
        score: 0,
    };
    // the method that checks the correct answer
    checkAnswer = (answer) => {
        const { correctAnswers, step, score } = this.state;
        if (answer === correctAnswers[step]) {
            this.setState({
                score: score + 1,
                correctAnswer: correctAnswers[step],
                clickedAnswer: answer,
            });
        } else {
            this.setState({
                correctAnswer: 0,
                clickedAnswer: answer,
            });
        }
    };
    // method to move to the next question
    nextStep = (step) => {
        this.setState({
            step: step + 1,
            correctAnswer: 0,
            clickedAnswer: 0,
        });
    };
    abc = (score) => {
        var bubblesec = document.getElementById('bubble-sec');
        var backgroundimage = document.getElementById('quizbanner');
        var timersection = document.getElementById('timer-sec');
        var resultcolumn = document.getElementById('result-column');
        timersection.style.display = 'none';
        bubblesec.className = 'resulttext';
        resultcolumn.className = 'col-sm-12';
        resultcolumn.style.textAlign = 'center';
        var para = document.createElement("p");
        var claimbtn = document.createElement("a");
        claimbtn.className = "claimbtn";

        para.className = "greetingtxt"
        if (score < 6) {
            backgroundimage.src = '../../../assets/imgs/hardluck.png';
            para.innerText = "Hard luck ! next time";
            document.body.appendChild(para);
        } else {
            backgroundimage.src = '../../../assets/imgs/congrats.png';
            // greetingtxt.innerHTML = "Congratulations";
            para.innerText = "congratulations";
            claimbtn.innerHTML = "Claim Reward";
            claimbtn.href = "/claimreward"
            document.body.appendChild(para);
            document.body.appendChild(claimbtn);

        }
    }

    render() {
        let { quiestions, answers, correctAnswer, clickedAnswer, step, score } =
            this.state;
        return (
            <>
                <Headerquiz></Headerquiz>
                <div className="quiz-body">
                    <img id="quizbanner" src="../../../assets/imgs/wolfquiz-bg.png" alt="" className="quizbanner" />
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div id="timer-sec" className="timer-section">
                                    <div className="row row align-items-center ">
                                        {/* <div className="col-sm-3">
                                        <div className="timewrapper">
                                            <img className="clock-ico" src={clockico} alt="" />
                                            <h3 className="time">03:00</h3>
                                        </div>
                                    </div> */}
                                        <div className="col-sm-5">
                                            <h3 className="time">
                                                <span className="green-color">Total Questions : </span>
                                                10
                                            </h3>
                                        </div>
                                        <div className="col-sm-4">
                                            <h3 className="time">
                                                <span className="green-color"> &nbsp; Attempt : </span>1
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="result-column" className="col-sm-6">
                                <div id="bubble-sec" className="bubble-pop bubb-tit">
                                    {step <= Object.keys(quiestions).length ?
                                        (
                                            <>
                                                <div className="questionwrapper">
                                                    <Question question={quiestions[step]} />
                                                </div>
                                                <div className="anserandbtn">
                                                    <Answer
                                                        answer={answers[step]}
                                                        step={step}
                                                        checkAnswer={this.checkAnswer}
                                                        correctAnswer={correctAnswer}
                                                        clickedAnswer={clickedAnswer}
                                                    />
                                                    <button
                                                        className="NextStep"
                                                        disabled={
                                                            clickedAnswer && Object.keys(quiestions).length >= step
                                                                ? false
                                                                : true
                                                        }
                                                        onClick={() => this.nextStep(step)}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="resultsdiv">
                                                <p className="scoreing">Your score {score} out of {Object.keys(quiestions).length}</p>
                                                {this.abc(score)}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
