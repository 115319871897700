import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Brucevol1 from "../assets/imgs/bruce.png";
import frontgold from "../assets/imgs/FRONTGOLD.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import Psycho from "../assets/imgs/psycho.png";
import Vision from "../assets/imgs/mr-vision.jpg";
import Gibran from "../assets/imgs/dr-jibran.jpg";
import Ivy from "../assets/imgs/ivy.jpg";
import Drx from "../assets/imgs/dr-x.jpg";
import { Link } from "react-router-dom"

export default class Readforrentslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      Arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <Slider {...settings}>
          <div>
            <Link to="/Bruce">
              <div className="single-comic">
                <img className="single-comic-img" src={Brucevol1} alt="" />
                <h3 className="single-title">DR.BRUCE VOL 1 PILOT EPISODE</h3>
                <div className="nameandprice">
                  <div className="name">Supert Vet</div>
                  <div className="price">
                    <img src={frontgold} alt="" />
                    <p className="price-para">
                      Free
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/nina" className="single-links">

              <div className="single-comic">
                <img className="single-comic-img" src={Nina} alt="" />
                <h3 className="single-title">DR.Nina VOL 1 PILOT EPISODE</h3>
                <div className="nameandprice">
                  <div className="name">Supert Vet</div>
                  <div className="price">
                    <img src={frontgold} alt="" />
                    <p className="price-para">
                      Free
                    </p>
                  </div>
                </div>
              </div>
            </Link>

          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Steelshot} alt="" />
              <h3 className="single-title">DR.Steelshot VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Wolf} alt="" />
              <h3 className="single-title">DR.Wolf VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Psycho} alt="" />
              <h3 className="single-title">DR.Psycho VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Vision} alt="" />
              <h3 className="single-title">Mr.vision VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Gibran} alt="" />
              <h3 className="single-title">DR.Gibran VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Ivy} alt="" />
              <h3 className="single-title">Dr.Ivy VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-comic">
              <img className="single-comic-img" src={Drx} alt="" />
              <h3 className="single-title">DR.X VOL 1 PILOT EPISODE</h3>
              <div className="nameandprice">
                <div className="name">Supert Vet</div>
                <div className="price">
                  <img src={frontgold} alt="" />
                  <p className="price-para">
                    300 - <span>Buy</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}
