import React, { Component } from "react";
import Footer from "../Footer";
import Headerquiztwo from "../Headerquiztwo";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import hammer from "../../assets/imgs/events/hammer.png";
import potion from "../../assets/imgs/events/potions.png";

export default class History extends Component {
  COLORS = ["#80CD03", "#FF006B"];
  pieData = [
    {
      name: "Win",
      value: 70,
    },
    {
      name: "Lose",
      value: 30,
    },
  ];
  CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
        </div>
      );
    }
    return null;
  };
  render() {
    return (
      <>
        <Headerquiztwo />

        <div className="historysection">
          <div className="container">
            <div className="row">
              <h3 className="event-title">History</h3>
            </div>
          </div>
          <ul id="forham" className="nav main-navigation">
            <li>
              <Link className="nav-items">
                <span className="green-color">Total Players:</span> 2000
              </Link>
            </li>
            <li>
              <Link className="nav-items">
                <span className="green-color">winners :</span> 200
              </Link>
            </li>
            <li>
              <Link className="nav-items">
                <span className="green-color">loss : </span> 1000
              </Link>
            </li>
            <li>
              <Link className="nav-items">
                <span className="green-color">playing :</span> 800
              </Link>
            </li>
            <li>
              <Link className="nav-items">
                <span className="green-color">ends in :</span> 24 hours
              </Link>
            </li>
          </ul>

          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="event-title wlratio">Win / Loss Ratio</h3>
                <PieChart width={320} height={300}>
                  <Pie
                    data={this.pieData}
                    color="#000000"
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#C7F58C"
                  >
                    {this.pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={this.COLORS[index % this.COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip content={<this.CustomTooltip />} />
                  <Legend />
                </PieChart>
              </div>
              <div className="col-sm-6">
                <h3 className="event-title re">Rewards Earn </h3>
                <div className="reward-wrapper">
                  <h3 className="event-title reward">Blastic hammer</h3>
                  <img src={hammer} alt="" />
                </div>

                <div className="reward-wrapper" style={{ marginTop: "20px" }}>
                  <h3 className="event-title reward">Healing Potion </h3>
                  <img src={potion} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
