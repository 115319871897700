import React, { Component } from "react";
import Footer from "../Footer";
import Headerquiztwo from "../Headerquiztwo";
import { Link } from "react-router-dom";
import leaderborad1 from "../../assets/imgs/events/leaderboard1.png";
import leaderborad2 from "../../assets/imgs/events/leaderboard2.png";
import leaderborad3 from "../../assets/imgs/events/leaderboard3.png";
import player1 from "../../assets/imgs/events/player1.png";
import player2 from "../../assets/imgs/events/player2.png";
import player3 from "../../assets/imgs/events/player3.png";
import player4 from "../../assets/imgs/events/player4.png";
import player5 from "../../assets/imgs/events/player5.png";

import hammer from "../../assets/imgs/events/hammer.png";
import potion from "../../assets/imgs/events/potions.png";

export default class Leaderboard extends Component {
  render() {
    return (
      <>
        <Headerquiztwo />

        <div className="historysection">
          <div className="container">
            <div className="row">
              <h3 className="event-title">Leaderboard</h3>
              <div className="table-wrapper">
                <table className="leaderboardtable">
                  <thead>
                    <th>Position</th>
                    <th>Name</th>
                    <th>Player</th>
                    <th>Correct Answer</th>
                    <th>Wrong Answer</th>
                    <th>Rewards Win</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          className="leaderboradpos-img"
                          src={leaderborad1}
                          alt=""
                        />
                      </td>
                      <td>Sarah Jay</td>
                      <td>
                        <img
                          className="leaderboarplayer-img"
                          src={player1}
                          alt=""
                        />
                      </td>
                      <td>10</td>
                      <td>0</td>
                      <td>
                        <img className="hammer-bd" src={hammer} alt="" />
                        <img className="potion-bd" src={potion} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          className="leaderboradpos-img"
                          src={leaderborad2}
                          alt=""
                        />
                      </td>
                      <td>wilson B</td>
                      <td>
                        <img
                          className="leaderboarplayer-img"
                          src={player2}
                          alt=""
                        />
                      </td>
                      <td>8</td>
                      <td>2</td>
                      <td>
                        <img className="hammer-bd" src={hammer} alt="" />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          className="leaderboradpos-img"
                          src={leaderborad3}
                          alt=""
                        />
                      </td>
                      <td>jack zed</td>
                      <td>
                        <img
                          className="leaderboarplayer-img"
                          src={player3}
                          alt=""
                        />
                      </td>
                      <td>6</td>
                      <td>4</td>
                      <td>
                        <img className="hammer-bd" src={potion} alt="" />
                      </td>
                    </tr>

                    <tr>
                      <td>4</td>
                      <td>ravinder</td>
                      <td>
                        <img
                          className="leaderboarplayer-img"
                          src={player4}
                          alt=""
                        />
                      </td>
                      <td>4</td>
                      <td>6</td>
                      <td>
                        <img className="hammer-bd" src={hammer} alt="" />
                      </td>
                    </tr>

                    <tr>
                      <td>5</td>
                      <td>morgan</td>
                      <td>
                        <img
                          className="leaderboarplayer-img"
                          src={player5}
                          alt=""
                        />
                      </td>
                      <td>3</td>
                      <td>7</td>
                      <td>
                        <img className="hammer-bd" src={potion} alt="" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
