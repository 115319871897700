import React, { Component } from "react";

import Footer from "./Footer";
import Header from "./Header";
import Brucevol1 from "../assets/imgs/bruce.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import Comicslider from "./SuperComics-slider";
import Videoslider from "./Video-slider";
import Readforrentslider from "./Readforrent-slider";
import svunl from "../assets/imgs/svunlimited.png";
import Psycho from "../assets/imgs/psycho.png";
import Vision from "../assets/imgs/mr-vision.jpg";
import Gibran from "../assets/imgs/dr-jibran.jpg";
import Ivy from "../assets/imgs/ivy.jpg";
import Drx from "../assets/imgs/dr-x.jpg";

export default class Onrent extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="main-bg onrent">
          <div className="main-bg-overlay">
            <div className="first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <img src={Brucevol1} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="single-comic-tit">
                          Dr.Bruce : Super Vet
                        </h3>
                        <p className="text-comics">May 25,2022</p>
                        <h3 className="published">Published by:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="forbottomalign">
                      <div className="dandbtnwrap">
                        <div className="daymonthsyears">
                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Day"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Month"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Year"
                          />
                        </div>

                        <div className="buttons">
                          <div className="forsvets">
                            <a
                              href="#"
                              target="_blank"
                              className="single-comics-btns svet-btn"
                            >
                              500 SVETS
                            </a>
                          </div>
                          <a
                            href="#"
                            target="_blank"
                            className="single-comics-btns buynow"
                          >
                            Read
                          </a>
                          <a className="single-comics-btns onrent">On Rent</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <img src={Nina} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="single-comic-tit">Nina : Super Vet</h3>
                        <p className="text-comics">May 25,2022</p>
                        <h3 className="published">Published by:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="forbottomalign">
                      <div className="dandbtnwrap">
                        <div className="daymonthsyears">
                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Day"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Month"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Year"
                          />
                        </div>

                        <div className="buttons">
                          <div className="forsvets">
                            <a
                              href="#"
                              target="_blank"
                              className="single-comics-btns svet-btn"
                            >
                              500 SVETS
                            </a>
                          </div>
                          <a
                            href="#"
                            target="_blank"
                            className="single-comics-btns buynow"
                          >
                            Read
                          </a>
                          <a className="single-comics-btns onrent">On Rent</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <img src={Steelshot} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="single-comic-tit">
                          Dr.Steelshot : Super Vet
                        </h3>
                        <p className="text-comics">May 25,2022</p>
                        <h3 className="published">Published by:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="forbottomalign">
                      <div className="dandbtnwrap">
                        <div className="daymonthsyears">
                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Day"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Month"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Year"
                          />
                        </div>

                        <div className="buttons">
                          <div className="forsvets">
                            <a
                              href="#"
                              target="_blank"
                              className="single-comics-btns svet-btn"
                            >
                              500 SVETS
                            </a>
                          </div>
                          <a
                            href="#"
                            target="_blank"
                            className="single-comics-btns buynow"
                          >
                            Read
                          </a>
                          <a className="single-comics-btns onrent">On Rent</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <img src={Wolf} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="single-comic-tit">
                          Dr.Wolf : Super Vet
                        </h3>
                        <p className="text-comics">May 25,2022</p>
                        <h3 className="published">Published by:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="forbottomalign">
                      <div className="dandbtnwrap">
                        <div className="daymonthsyears">
                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Day"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Month"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Year"
                          />
                        </div>

                        <div className="buttons">
                          <div className="forsvets">
                            <a
                              href="#"
                              target="_blank"
                              className="single-comics-btns svet-btn"
                            >
                              500 SVETS
                            </a>
                          </div>
                          <a
                            href="#"
                            target="_blank"
                            className="single-comics-btns buynow"
                          >
                            Read
                          </a>
                          <a className="single-comics-btns onrent">On Rent</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="first-row lastrow">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <img src={Ivy} alt="" />
                  </div>
                  <div className="col-sm-4">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="single-comic-tit">Dr.Ivy : Super Vet</h3>
                        <p className="text-comics">May 25,2022</p>
                        <h3 className="published">Published by:</h3>
                        <p className="text-comics">Super Vet</p>
                        <h3 className="published">Writer:</h3>
                        <p className="text-comics">Maheera Ahmed</p>
                        <h3 className="published">Cover Artist:</h3>
                        <p className="text-comics">Sisa Bodani</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="forbottomalign">
                      <div className="dandbtnwrap">
                        <div className="daymonthsyears">
                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Day"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Month"
                          />

                          <input
                            className="daymonthsyearsinput"
                            type="text"
                            name=""
                            id=""
                            placeholder="Year"
                          />
                        </div>

                        <div className="buttons">
                          <div className="forsvets">
                            <a
                              href="#"
                              target="_blank"
                              className="single-comics-btns svet-btn"
                            >
                              500 SVETS
                            </a>
                          </div>
                          <a
                            href="#"
                            target="_blank"
                            className="single-comics-btns buynow"
                          >
                            Read
                          </a>
                          <a className="single-comics-btns onrent">On Rent</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
