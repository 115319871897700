import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import SingleComics from "./components/Single-comic";
import SingleComicsNina from "./components/Single-comicnina";
import Characters from "./components/Characters";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Characterprofile from "./components/Characterprofile";
import ScrollToTop from "./components/Scrolltotop";
import Videospage from "./components/videoComics/Videocomicsmain";
import Unlimited from "./components/Unlimited/Unlimited";
import Newss from "./components/News/News";
import Supervideocomicsall from "./components/videoComics/supervideocomicsall";
import Supercomics from "./components/supercomics/supercomics";
import Onrent from "./components/Onrent";
import Supervetteam from "./components/Supervetteam/Supervetteam";
import Userprofile from "./components/userprofile";
import Mytanents from "./components/Mytanets";
import Exploreall from "./components/Exploreall/Explorall";
import Singlepost1 from "./components/News/blogpost1";
import Singlepost2 from "./components/News/blogpost2";
import Singlepost3 from "./components/News/blogpost3";
import Singlepost4 from "./components/News/blogpost4";
import Events from "./components/Events/Events";
import Quizstart from "./components/Events/quizstart";
import Historyleaderboard from "./components/Events/historyleaderboard";
import History from "./components/Events/History";
import Leaderboard from "./components/Events/Leaderboard";
import Quiz from "./components/Events/QuizMain";
import Claimreward from "./components/Events/claimreward";
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/bruce">
              <SingleComics />
            </Route>
            <Route exact path="/nina">
              <SingleComicsNina />
            </Route>
            <Route exact path="/Characters">
              <Characters />
            </Route>
            <Route exact path="/Bruceprofile">
              <Characterprofile />
            </Route>
            <Route exact path="/videos">
              <Videospage />
            </Route>
            <Route exact path="/unlimited">
              <Unlimited />
            </Route>
            <Route exact path="/news">
              <Newss />
            </Route>
            <Route exact path="/supervideocomics">
              <Supervideocomicsall />
            </Route>
            <Route exact path="/supercomics">
              <Supercomics />
            </Route>
            <Route exact path="/onrent">
              <Onrent />
            </Route>
            <Route exact path="/supervetteam">
              <Supervetteam />
            </Route>
            <Route exact path="/exploreall">
              <Exploreall />
            </Route>
            <Route exact path="/post1">
              <Singlepost1 />
            </Route>
            <Route exact path="/post2">
              <Singlepost2 />
            </Route>
            <Route exact path="/post3">
              <Singlepost3 />
            </Route>
            <Route exact path="/post4">
              <Singlepost4 />
            </Route>
            {/* <Route exact path="/profile" ><Userprofile /></Route> */}
            {/* <Route exact path="/mytanents" ><Mytanents /></Route> */}
            <Route exact path="/competition">
              <Events />
            </Route>
            <Route exact path="/quiz">
              <Quizstart />
            </Route>
            <Route exact path="/historyleaderboard">
              <Historyleaderboard />
            </Route>
            <Route exact path="/history">
              <History />
            </Route>
            <Route exact path="/leaderboard">
              <Leaderboard />
            </Route>
            <Route exact path="/quizmain">
              <Quiz />
            </Route>
            <Route exact path="/claimreward">
              <Claimreward />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}
export default App;
