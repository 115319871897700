import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Videoposter from "../../assets/imgs/video-poster.png"

import trailer1 from "../../assets/imgs/news/bruce-news.png";
import trailer2 from "../../assets/imgs/news/ivynews.png";
import trailer3 from "../../assets/imgs/news/brucesnews.png";
import trailer4 from "../../assets/imgs/news/trailer.png";
import psychothumbnail from "../../assets/imgs/news/psycho.png";
import steelshot from "../../assets/imgs/news/steelshot.png";
import cat from "../../assets/imgs/news/cat.png";
import ivy2 from "../../assets/imgs/news/ivy2.png";
import game1 from "../../assets/imgs/news/game1.png";
import game2 from "../../assets/imgs/news/game2.png";
import game3 from "../../assets/imgs/news/game3.png";
import { Link } from "react-router-dom";
import Relatedposts from "../relatedposts";
import Newsbannerbg from "../../assets/imgs/banners/newssbanner-bg.png"


export default class Newss extends Component {
  render() {
    return (
      <>
        <Header />

        <div class="containers">
          <img style={{ width: '100%' }} src={Newsbannerbg} alt="Snow" />

          <div class="centered">  <div className="ch-bann-txt-wrap">
            <h2 className="character-banner-heading">
              <span className="spancoloryellow">SUPER VET </span>
              NEWS
            </h2>
            <h3 className="character-banner-text">
              Now Up To Date With Super Vet..!
            </h3>
          </div></div>
        </div>




        <div className="main-bg Newss">
          <div className="main-bg-overlay">
            <div className="call-to-action">
              <div className="container">

                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 ">
                      <h4 className="call-to-tit">Trailer news</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-container">
              <div className="container">
                <div className="row">
                  <video
                    poster={Videoposter}
                    id="vid-sec"
                    playsInline
                    controls
                    disablePictureInPicture
                    controlslist="nodownload noplaybackrate nopictureinpicture noremoteplayback "
                    style={{ height: "100%", width: "90%" }}
                  >
                    <source
                      src="https://d1ztdtzrrh7hlu.cloudfront.net/vet/video/teaser.mp4"
                      type="video/mp4"
                    ></source>
                    Your browser does not support HTML5 video.
                  </video>


                </div>

                <div className="row">
                  <h3 className="trailer-tit">
                    SUPERVET TRAILER
                  </h3>
                  <p>
                    One of the most unique SUPER VET Legacy launches last year had to be the return of  VISION. Among the House of Ideas'
                    most haunting heroes, Marc Spector and his many alter-egos have gone on quite a journey in recent months. And this week,
                    this multifaceted man reaches a merry milestone with VISION #200!
                  </p>
                </div>


                <div className="trailer-section">
                  <div className="row">
                    <div className="col-sm-3">
                      <img className="trailer-img" src={trailer1} alt="" />
                      <h3 className="trailer-h">
                        BRUCE TRAILER
                      </h3>
                    </div>

                    <div className="col-sm-3">
                      <img className="trailer-img" src={trailer2} alt="" />
                      <h3 className="trailer-h">
                        Now Up To Date With Super Vet..!
                      </h3>
                    </div>

                    <div className="col-sm-3">
                      <img className="trailer-img" src={trailer3} alt="" />
                      <h3 className="trailer-h">
                        IVY TEAILER
                      </h3>
                    </div>

                    <div className="col-sm-3">
                      <img className="trailer-img" src={trailer4} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="call-to-action">
              <div className="container">

                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 ">
                      <h4 className="call-to-tit">Latest Comic News</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="latest-comics-news">
              <div className="container">
                <div className="row">
                  <div className="col-sm-9 ">
                    <img className="img-fluid" src={psychothumbnail} alt="" />
                    <h3 className="trailer-h">
                      NEW WEPON TRAILER
                    </h3>
                    <p className="call-to-p">
                      One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION. Among the House of Ideas' most haunting heroes, Marc
                      Spector and his many alter-egos have gone on quite a journey in recent months. And this week, this multifaceted man reaches a merry milestone with
                      VISION #200!
                    </p>
                  </div>

                  <div className="col-sm-3">
                    <div className="post1">
                      <img className="img-fluid" src={steelshot} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                      <p className="call-to-p">
                        One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION.
                      </p>
                    </div>

                    <div className="post2">
                      <img className="img-fluid" src={cat} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                      <p className="call-to-p">
                        One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION.
                      </p>

                    </div>
                    <div className="post3">
                      <img className="img-fluid" src={ivy2} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                      <p className="call-to-p">
                        One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION.
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="call-to-action">
              <div className="container">

                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 ">
                      <h4 className="call-to-tit">Latest Game News</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="latest-comics-news">
              <div className="container">
                <div className="row">
                  <div className="col-sm-9 ">
                    <img className="img-fluid" src={game1} alt="" />
                    <h3 className="trailer-h">
                      NEW WEPON TRAILER
                    </h3>
                    <p className="call-to-p">
                      One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION. Among the House of Ideas' most haunting heroes, Marc
                      Spector and his many alter-egos have gone on quite a journey in recent months. And this week, this multifaceted man reaches a merry milestone with
                      VISION #200!
                    </p>
                  </div>

                  <div className="col-sm-3">
                    <div className="post1">
                      <img className="img-fluid" src={game2} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                      <p className="call-to-p">
                        One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION.
                      </p>
                    </div>

                    <div className="post2">
                      <img className="img-fluid" src={game3} alt="" />
                      <h3 className="trailer-h">
                        NEW WEPON TRAILER
                      </h3>
                      <p className="call-to-p">
                        One of the most unique SUPERVET Legacy launches last year had to be the return of  VISION.
                      </p>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">

                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 ">
                      <h4 className="call-to-tit">Super News</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>


            <Relatedposts></Relatedposts>


          </div>
        </div>

        <Footer />
      </>
    );
  }
}
