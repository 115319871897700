import React, { Component } from "react";
import Footer from "../Footer";
import Headerquiztwo from "../Headerquiztwo";
import { Link } from "react-router-dom";
export default class Historyleaderboard extends Component {
  render() {
    return (
      <>
        <Headerquiztwo />
        <img
          className="historyleaderboard"
          src="../../../assets/imgs/historyleaderboard.png"
          alt=""
        />

        <div className="twobtns-wrap">
          <Link className="historyleaderboardbtn1" to="/history">
            History
          </Link>

          <Link className="historyleaderboardbtn2" to="/leaderboard">
            Leaderboard
          </Link>
        </div>

        <Footer />
      </>
    );
  }
}
