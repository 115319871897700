import React, { Component } from "react";
import Footer from "./Footer";
import bannerprofile from "../assets/imgs/banners/profile-banner.png";
import Brucevol1 from "../assets/imgs/bruce.png";
import frontgold from "../assets/imgs/FRONTGOLD.png";
import Nina from "../assets/imgs/nina.png";
import Steelshot from "../assets/imgs/steelshot.png";
import Wolf from "../assets/imgs/wolf.png";
import Psycho from "../assets/imgs/psycho.png";
import Vision from "../assets/imgs/mr-vision.jpg";
import Gibran from "../assets/imgs/dr-jibran.jpg";
import Ivy from "../assets/imgs/ivy.jpg";
import Drx from "../assets/imgs/dr-x.jpg";
import { Link } from "react-router-dom";
import videoico from "../assets/imgs/videoicon.png";
import profileimg from "../assets/imgs/profile/profile-img.png";
import profileimgicon from "../assets/imgs/profile/icon.png";
import cameraico from "../assets/imgs/profile/camera.png";
import Headerprofile from "./Headerprofile";
import proimg1 from "../assets/imgs/profile/tanents/1.png";
import proimg2 from "../assets/imgs/profile/tanents/2.png";
import proimg3 from "../assets/imgs/profile/tanents/3.png";
import proimg4 from "../assets/imgs/profile/tanents/4.png";
import DateCountdown from 'react-date-countdown-timer';
import Countdown from "../Countdown";


export default class Mytanents extends Component {
  render() {
    return (
      <>
        <Headerprofile />
        <div className="main-bg tanents-page">
          <div className="main-bg-overlay">
            <div className="profile-banner-section">
              <img style={{ width: "100%" }} src={bannerprofile} alt="" />
              <div className="anthor-div-for-center text-center">
                <div className="camera-ico-wrapper">
                  <img className="cameraico" src={cameraico} alt="" /> Edit
                  Cover Photo
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="profile-img-section">
                  <div className="imgswrap">
                    <img className="profile-img" src={profileimg} alt="" />
                    <img className="icon-photo" src={profileimgicon} alt="" />
                  </div>
                  <div className="text-wrapper-profile-section">
                    <h3 className="pro-title">Wassam Qazi</h3>
                    <p className="joining-date">Joined May 25 2022</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3">
                      <h4 className="call-to-tit">My comics</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>



            <div className="tanents-first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="for-flex">
                      <img className="proimg" src={proimg1} alt="" />
                      <div className="text-wapper">
                        <h3 className="pro-title">
                          Harry J
                        </h3>
                        <p className="joining-date">Joined May 25 2022</p>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-2">
                    <img className="cover-img-tanents" src={Brucevol1} alt="" />
                  </div>
                  <div className="col-sm-3">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rent Duration</h3>
                        <p className="days3">3 Days</p>
                        <p className="daterange">May 27 2022 - May 30 2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rental Price</h3>
                        <p className="days3"><img src={frontgold} alt="" /> 300 svet <span style={{ color: '#05FF69', fontWeight: 'bold' }}>RENT</span></p>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">End Loop</h3>
                        <Countdown />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tanents-first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="for-flex">
                      <img className="proimg" src={proimg2} alt="" />
                      <div className="text-wapper">
                        <h3 className="pro-title">
                          Harry J
                        </h3>
                        <p className="joining-date">Joined May 25 2022</p>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-2">
                    <img className="cover-img-tanents" src={Nina} alt="" />
                  </div>
                  <div className="col-sm-3">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rent Duration</h3>
                        <p className="days3">3 Days</p>
                        <p className="daterange">May 27 2022 - May 30 2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rental Price</h3>
                        <p className="days3"><img src={frontgold} alt="" /> 300 svet <span style={{ color: '#05FF69', fontWeight: 'bold' }}>RENT</span></p>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">End Loop</h3>
                        <Countdown />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tanents-first-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="for-flex">
                      <img className="proimg" src={proimg3} alt="" />
                      <div className="text-wapper">
                        <h3 className="pro-title">
                          Harry J
                        </h3>
                        <p className="joining-date">Joined May 25 2022</p>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-2">
                    <img className="cover-img-tanents" src={Wolf} alt="" />
                  </div>
                  <div className="col-sm-3">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rent Duration</h3>
                        <p className="days3">3 Days</p>
                        <p className="daterange">May 27 2022 - May 30 2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rental Price</h3>
                        <p className="days3"><img src={frontgold} alt="" /> 300 svet <span style={{ color: '#05FF69', fontWeight: 'bold' }}>RENT</span></p>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">End Loop</h3>
                        <Countdown />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tanents-first-row last-row">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="for-flex">
                      <img className="proimg" src={proimg4} alt="" />
                      <div className="text-wapper">
                        <h3 className="pro-title">
                          Harry J
                        </h3>
                        <p className="joining-date">Joined May 25 2022</p>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-2">
                    <img className="cover-img-tanents" src={Ivy} alt="" />
                  </div>
                  <div className="col-sm-3">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rent Duration</h3>
                        <p className="days3">3 Days</p>
                        <p className="daterange">May 27 2022 - May 30 2022</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">Rental Price</h3>
                        <p className="days3"><img src={frontgold} alt="" /> 300 svet <span style={{ color: '#05FF69', fontWeight: 'bold' }}>RENT</span></p>

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="for-middlement">
                      <div className="txt-wrapper-onrent">
                        <h3 className="rent-duration">End Loop</h3>
                        <Countdown />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
