import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Brucevol1 from "../../assets/imgs/bruce.png";
import frontgold from "../../assets/imgs/FRONTGOLD.png";
import videoico from "../../assets/imgs/videoicon.png";
import Nina from "../../assets/imgs/nina.png";
import Steelshot from "../../assets/imgs/steelshot.png";
import Wolf from "../../assets/imgs/wolf.png";
import Psycho from "../../assets/imgs/psycho.png";
import Vision from "../../assets/imgs/mr-vision.jpg";
import Ivy from "../../assets/imgs/ivy.jpg";
import Drx from "../../assets/imgs/dr-x.jpg";
import Gibran from "../../assets/imgs/dr-jibran.jpg";
import supervideocomicsbanner from "../../assets/imgs/banners/supervideobanner.png"

import commingsoon from "../../assets/imgs/commingsoon.png";
export default class Supervideocomicsall extends Component {
  render() {
    return (
      <>
        <Header />

        <div class="containers">
          <img style={{ width: '100%' }} src={supervideocomicsbanner} alt="Snow" />

          <div class="centered">
            <div className="ch-bann-txt-wrap">
              <h2 className="character-banner-heading">
                <span className="spancoloryellow">SUPER VET </span>
                Video Comics
              </h2>
              <h3 className="character-banner-text">
                We Are Here To Rescue Helpless Animals
              </h3>
            </div></div>
        </div>


        <div className="main-bg">
          <div className="call-to-action">
            <div className="container">
              <div className="rectangle-cta">
                <div className="row">
                  <div className="col-sm-3 nopaddingleft">
                    <h4 className="call-to-tit featured-characters">
                      Video Comics
                    </h4>
                  </div>
                  <div className="col-sm-6 for-center">
                    <p className="call-to-p">
                      Read To Earn : Read Super Vet Comics To earn rewards !
                    </p>
                  </div>
                  <div className="col-sm-3 for-center"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="comics-section">
            <div className="container-xxl">
              <div className="row">
                <div className="comics-wrapper">
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Brucevol1}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">
                      DR.BRUCE VOL 1 pilot episode
                    </h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"></div>
                    <img
                      className="single-comic-img videopage"
                      src={Nina}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Nina VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Steelshot}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Steelshot VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"></div>
                    <img
                      className="single-comic-img videopage"
                      src={Wolf}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Wolf VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Psycho}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Psycho VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Gibran}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Gibran VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Vision}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">MR.Vision VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Ivy}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.Ivy VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Drx}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.X VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-comic video">
                    <div className="image-overlay-videocomicsmain"> </div>
                    <img
                      className="single-comic-img videopage"
                      src={Brucevol1}
                      alt=""
                    />
                    <img className="video-iconz" src={videoico} alt="" />

                    <h3 className="single-title ">DR.BRUCE VOL 1</h3>
                    <div className="nameandprice ">
                      <div className="name ">Supert Vet</div>
                      <div className="price">
                        <img src={frontgold} alt="" />
                        <p className="price-para">
                          300 - <span>Buy</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>

                  <div className="single-comic ">
                    <img
                      className="single-comic-img"
                      src={commingsoon}
                      alt=""
                    />
                    <h3 className="single-title">Coming soon...</h3>
                    <div className="nameandprice">
                      <div className="name">Super Vet</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer-padding"></div>
        </div>

        <Footer />
      </>
    );
  }
}
