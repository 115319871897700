import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import charpagebg from "../../assets/imgs/banners/character-page-slider.png";
import slide1img from "../../assets/imgs/characters/charpage/DrPsycho.png"
import slide2img from "../../assets/imgs/characters/charpage/DR-GIBRAN.png"
import slide4img from "../../assets/imgs/characters/charpage/DR-xx.png"
import slide3img from "../../assets/imgs/characters/charpage/Drbrucefront.png"
import slide5img from "../../assets/imgs/characters/charpage/Drivyhumanform.png"


export default class Characterpageslider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      Arrows: true,
    };
    return (
      <div className="main-slider-slider1">
        <Slider {...settings}>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="text-wrapper">
                    <h3 className="first-title">get to know...</h3>
                    <h1 className="main-tit">Dr.Psycho</h1>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-slider">
                        Read more
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="col-sm-6 col-6"><img className="char-slide-single-img" src={slide1img} alt="" /></div>
              </div>
            </div>
            <img className="charpage-bg" src={charpagebg} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="text-wrapper">
                    <h3 className="first-title">get to know...</h3>
                    <h1 className="main-tit">Dr.Gibran</h1>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-slider">
                        Read more
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="col-sm-6 col-6"><img className="char-slide-single-img" src={slide2img} alt="" /></div>
              </div>
            </div>
            <img className="charpage-bg" src={charpagebg} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="text-wrapper">
                    <h3 className="first-title">get to know...</h3>
                    <h1 className="main-tit">Dr.Bruce</h1>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-slider">
                        Read more
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="col-sm-6 col-6"><img className="char-slide-single-img" src={slide3img} alt="" /></div>
              </div>
            </div>
            <img className="charpage-bg" src={charpagebg} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="text-wrapper">
                    <h3 className="first-title">get to know...</h3>
                    <h1 className="main-tit">Dr.X</h1>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-slider">
                        Read more
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="col-sm-6 col-6"><img className="char-slide-single-img" src={slide4img} alt="" /></div>
              </div>
            </div>
            <img className="charpage-bg" src={charpagebg} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <div className="text-wrapper">
                    <h3 className="first-title">get to know...</h3>
                    <h1 className="main-tit">Dr.Ivy</h1>
                    <div className="btn-wrapper">
                      <a href="#" className="btn-slider">
                        Read more
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="col-sm-6 col-6"><img className="char-slide-single-img" src={slide5img} alt="" /></div>
              </div>
            </div>
            <img className="charpage-bg" src={charpagebg} alt="" />
          </div>
          
        </Slider>
      </div>
    );
  }
}
