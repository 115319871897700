import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import unlimitedbanner from "../../assets/imgs/Unlimited/unlimitedbanner.png";
import Brucevol1 from "../../assets/imgs/Unlimited/BRUCEBUNDLE.png";
import Nina from "../../assets/imgs/Unlimited/nina.png";
import Steelshot from "../../assets/imgs/Unlimited/steelshot.png";
import Wolf from "../../assets/imgs/Unlimited/wolf.png";
import Psycho from "../../assets/imgs/Unlimited/pyscho.png";
import frontgold from "../../assets/imgs/FRONTGOLD.png";
import { Link } from "react-router-dom";
import Unlimitedslider from "./Unlimitedslider";

import spot1 from "../../assets/imgs/characterspotlight/spot1.png";
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
import Relatedposts from "../relatedposts";

export default class Unlimited extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="unlimited-banner">
          <img className="unl-banner" src={unlimitedbanner} alt="" />
          <div className="container">
            <div className="row">
              <h3 className="unlimite-banner-txt">
                Read more <span className="earn-more-clr">Earn more</span> with
                <span className="unl-clr"> Super Vet Unlimited</span>
              </h3>
            </div>
          </div>
        </div>

        <div id="unlimited-page" className="main-bg">
          <div className="main-bg-overlay">
            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 nopaddingleft">
                      <h4 className="call-to-tit">New Bundles</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center">
                      {/* <a className="call-to-btn" href="Join-now">
                      Join Now
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="comics-section">
              <div className="container-xxl">
                <div className="row">
                  <div className="comics-wrapper">
                    <div className="single-comic">
                      <Link to="#" className="single-links">
                        <p className="new-lable">NEW</p>
                        <img
                          className="single-comic-img"
                          src={Brucevol1}
                          alt=""
                        />
                        <h3 className="single-title">
                          sUPERVET UNLIMTED B1 3 COMICS
                        </h3>
                        <div className="nameandprice">
                          <div className="name">Supert Vet</div>
                          <div className="price">
                            <img src={frontgold} alt="" />
                            <p className="price-para">
                              600 - <span>Buy</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Nina} alt="" />
                      <h3 className="single-title">
                        sUPERVET UNLIMTED B2 COMICS
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            600 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img
                        className="single-comic-img"
                        src={Steelshot}
                        alt=""
                      />
                      <h3 className="single-title">
                        sUPERVET UNLIMTED B3 2 COMICS
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            600 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Wolf} alt="" />
                      <h3 className="single-title">
                        sUPERVET UNLIMTED B4 2 COMICS
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            600 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="single-comic">
                      <p className="new-lable">NEW</p>
                      <img className="single-comic-img" src={Psycho} alt="" />
                      <h3 className="single-title">
                        sUPERVET UNLIMTED B5 2 COMICS
                      </h3>
                      <div className="nameandprice">
                        <div className="name">Supert Vet</div>
                        <div className="price">
                          <img src={frontgold} alt="" />
                          <p className="price-para">
                            600 - <span>Buy</span>
                          </p>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="call-to-action">
              <div className="container">
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 nopaddingleft">
                      <h4 className="call-to-tit">Super Bundles</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center">
                      {/* <a className="call-to-btn" href="Join-now">
                      Join Now
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="comics-slider">
              <div className="container-xxl">
                <div className="row">
                  <div className="for-border">
                    <Unlimitedslider />
                  </div>
                </div>
              </div>
            </div>

            <div className="call-to-action">
              <div className="container">
                <img src="../../public/assets/imgs/b1.png" alt="" />
                <div className="rectangle-cta">
                  <div className="row">
                    <div className="col-sm-3 nopaddingleft">
                      <h4 className="call-to-tit">Super News</h4>
                    </div>
                    <div className="col-sm-6 for-center">
                      <p className="call-to-p">
                        Read To Earn : Read Super Vet Comics To earn rewards !
                      </p>
                    </div>
                    <div className="col-sm-3 for-center"></div>
                  </div>
                </div>
              </div>
            </div>

            <Relatedposts></Relatedposts>

            <div className="container">
              <div className="playandearnsection">
                <div className="row">
                  <h3 className="letsplayandearn">Let’s Play And Earn !</h3>
                  <button className="letsplayandearnbtn">PLAY</button>
                </div>
              </div>
            </div>
            <div className="spacer-padding"></div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
