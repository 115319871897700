import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import team1 from "../../assets/imgs/characters/team/team1.png";
import team2 from "../../assets/imgs/characters/team/team2.png";
import team3 from "../../assets/imgs/characters/team/team3.png";
import team4 from "../../assets/imgs/characters/team/team4.png";
import team5 from "../../assets/imgs/characters/team/team5.png";
import characterbruce from "../../assets/imgs/characters/charbruce.png";
import charactergibran from "../../assets/imgs/characters/chargibran.png";
import characterivy from "../../assets/imgs/characters/charivy.png";
import charactersteel from "../../assets/imgs/characters/charsteel.png";
import characternina from "../../assets/imgs/characters/charnina.png";
import Characterpageslider from "../sliders/Characterpageslider";
import Characterbanner from "../../assets/imgs/banners/character-banner.png";
import spot1 from "../../assets/imgs/characterspotlight/spot1.png";
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
import { Link } from "react-router-dom";
export default class Exploreall extends Component {
    render() {
        return (
            <>
                <Header />
                <div class="containers">
                    <img style={{ width: '100%' }} src={Characterbanner} alt="Snow" />

                    <div class="centered">  <div className="ch-bann-txt-wrap">
                        <h2 className="character-banner-heading">
                            <span className="spancoloryellow">SUPER VET </span>
                            EXPLORE ALL
                        </h2>
                        <h3 className="character-banner-text">
                            We Are Here To Rescue Helpless Animals
                        </h3>
                    </div></div>
                </div>



                <div className="main-bg team">

                    {/* featured character */}
                    <div className="call-to-action">
                        <div className="container">
                            <img src="../../public/assets/imgs/b1.png" alt="" />
                            <div className="rectangle-cta">
                                <div className="row">
                                    <div className="col-sm-3 nopaddingleft">
                                        <h4 className="call-to-tit featured-characters">
                                            FEATURED cHARACTERS
                                        </h4>
                                    </div>
                                    <div className="col-sm-6 for-center">
                                        <p className="call-to-p">
                                            Read To Earn : Read Super Vet Comics To earn rewards !
                                        </p>
                                    </div>
                                    <div className="col-sm-3 for-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="Charactersection" className="comics-section">
                        <div className="container-xxl">
                            <div className="row">
                                <div className="comics-wrapper">
                                    <div className="single-character ">
                                        <Link to="/bruceprofile" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img
                                                    className="single-comic-img"
                                                    src={characterbruce}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">DR.BRUCE</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Supert Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img
                                                    className="single-comic-img"
                                                    src={charactergibran}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">DR.Gibran</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Supert Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img
                                                    className="single-comic-img"
                                                    src={characternina}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">Sister Nina</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Supert Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img
                                                    className="single-comic-img"
                                                    src={charactersteel}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">DR.Steelshot</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Supert Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img
                                                    className="single-comic-img"
                                                    src={characterivy}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">DR.Ivy</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Supert Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Character spotlight */}
                    <div className="call-to-action">
                        <div className="container">
                            <img src="../../public/assets/imgs/b1.png" alt="" />
                            <div className="rectangle-cta">
                                <div className="row">
                                    <div className="col-sm-3 nopaddingleft">
                                        <h4 className="call-to-tit">Character spotlight</h4>
                                    </div>
                                    <div className="col-sm-6 for-center">
                                        <p className="call-to-p">
                                            Read To Earn : Read Super Vet Comics To earn rewards !
                                        </p>
                                    </div>
                                    <div className="col-sm-3 for-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="character-spotlight">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="spot-wrapper">
                                        <img src={spot1} alt="" />
                                        <h3 className="spot-tit1">SUPER COMICS</h3>
                                        <h3 className="spot-tit2">When 'What If?’ Comes True</h3>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="spot-wrapper">
                                        <img src={spot2} alt="" />
                                        <h3 className="spot-tit1">TRAILERS & EXTRAS</h3>
                                        <h3 className="spot-tit2">Who’s Gona Help Under Sea</h3>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="spot-wrapper">
                                        <img src={spot3} alt="" />
                                        <h3 className="spot-tit1">SUPER COMICS</h3>
                                        <h3 className="spot-tit2">First Appearances: Vision</h3>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="spot-wrapper">
                                        <img src={spot4} alt="" />
                                        <h3 className="spot-tit1">Amazing Comics</h3>
                                        <h3 className="spot-tit2">Super Heroes</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* featured team */}
                    <div className="call-to-action">
                        <div className="container">
                            <img src="../../public/assets/imgs/b1.png" alt="" />
                            <div className="rectangle-cta">
                                <div className="row">
                                    <div className="col-sm-3 nopaddingleft">
                                        <h4 className="call-to-tit featured-characters">
                                            FEATURED Teams
                                        </h4>
                                    </div>
                                    <div className="col-sm-6 for-center">
                                        <p className="call-to-p">
                                            Read To Earn : Read Super Vet Comics To earn rewards !
                                        </p>
                                    </div>
                                    <div className="col-sm-3 for-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="Charactersection" className="comics-section">
                        <div className="container-xxl">
                            <div className="row">
                                <div className="comics-wrapper">
                                    <div className="single-character ">
                                        <Link to="#" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img className="single-comic-img" src={team1} alt="" />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">save the kind</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Super Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img className="single-comic-img" src={team2} alt="" />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">me and you</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Super Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img className="single-comic-img" src={team3} alt="" />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">The Duo Saviour</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Super Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img className="single-comic-img" src={team4} alt="" />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">one-man two-man</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Super Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="single-character">
                                        <Link to="/characters" className="single-links">
                                            <div className="single-character-wrapper">
                                                <img className="single-comic-img" src={team5} alt="" />
                                            </div>
                                            <div className="for-border-anime"></div>
                                            <div className="single-character-text-wrapper">
                                                <h3 className="single-title">be my eyes</h3>
                                                <div className="nameandprice">
                                                    <div className="name">Super Vet</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* play and earn */}
                    <div className="container">
                        <div className="playandearnsection">
                            <div className="row">
                                <h3 className="letsplayandearn">Let’s Play And Earn !</h3>
                                <button className="letsplayandearnbtn">PLAY</button>
                            </div>
                        </div>
                    </div>

                    <div id="chara-page-slider" className="character-page-slider">
                        <Characterpageslider />
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}
