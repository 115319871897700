import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../logo.png";
import cbtn from "../assets/imgs/c-button.png";
import comics from "../assets/imgs/comic-logo.png";
import Searchico from "../assets/imgs/icons/search-ico.png";
import React, { useState } from "react";
import { useEffect } from "react";
import web3 from "../connection/web3";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hamburger from "../assets/imgs/hamburger.png";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import characterbruce from "../assets/imgs/characters/charbruce.png";
import charactergibran from "../assets/imgs/characters/chargibran.png";
import charactersteel from "../assets/imgs/characters/charsteel.png";
import characternina from "../assets/imgs/characters/charnina.png";
import profileimg from "../assets/imgs/profile-img.png";

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [networkError, setNetworkError] = useState(undefined);
  const [userAdd, setUserAdd] = useState("");
  const [walletConnected, setWalletConnected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [tooltipText, settooltipText] = useState("");
  const HARDHAT_NETWORK_ID = "97";
  //const HARDHAT_NETWORK_ID = "56";
  const history = useHistory();
  // const alert = useAlert();

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const getCurrentWalletConnected = async () => {
    if (web3) {
      try {
        web3.eth.net.getId().then((netId) => {
          if (netId.toString() === HARDHAT_NETWORK_ID) {
            web3.eth.requestAccounts().then((addressArray) => {
              if (addressArray.length > 0) {
                setSelectedAddress(addressArray[0]);
                localStorage.setItem("address", addressArray[0]);
                if (!localStorage.getItem("signer") === null) {
                  //getSignIn();
                } else {
                  if (!(localStorage.getItem("signer") === addressArray[0])) {
                    //getSignIn();
                  }
                }
              } else {
                setSelectedAddress("");
                localStorage.setItem("address", "");
              }
            });
            // const addressArray = web3.eth.getAccounts();
          } else {
            toast("Please connect Metamask to BSC Testnet");
            setSelectedAddress("");
            localStorage.setItem("address", "");
          }
        });
      } catch (err) {
        setSelectedAddress("");
        localStorage.setItem("address", "");
      }
    } else {
      setSelectedAddress("");
      localStorage.setItem("address", "");
    }
  };

  useEffect(async () => {
    // settooltipText("Copy");
    await getCurrentWalletConnected();
    //setSelectedAddress(address);
    addWalletListener();
    chainListener();
  }, []);

  const signMessage = async ({ message }) => {
    const address = (await web3.eth.requestAccounts())[0];
    const signature = await web3.eth.personal.sign(message, address);
    return {
      signature,
      address,
      message,
    };
  };

  // const getSignIn = async () => {
  //   try {
  //     const sig = await signMessage({
  //       message: "Super Vet",
  //     });
  //     if (sig) {
  //       // await saveTodo(sig);
  //       // await timeout(8000);
  //       localStorage.setItem("signer", sig.address);
  //       window.location.reload();
  //       // alert.show("Your SignIn Hash: " + sig.signature);
  //     }
  //   } catch (err) {
  //     toast("Please Sign In To Continue......");
  //     setSelectedAddress("");
  //     localStorage.setItem("address", "");
  //     await timeout(2000);
  //     window.location.reload();
  //   }
  // };

  const connectWalletPressed = async () => {
    // console.log("inside connectWalletPressed");
    if (window.ethereum) {
      web3.eth.net.getId().then((netId) => {
        if (netId.toString() === HARDHAT_NETWORK_ID) {
          console.log("connectWalletPressed");
          const walletResponse = connectWallet();
          setSelectedAddress(walletResponse.address);
          localStorage.setItem("address", walletResponse.address);
          // getSignIn();
        } else {
          toast("Please connect Metamask to BSC Testnet");
        }
      });
    } else {
      toast(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
        };
      }
    } else {
      return {
        address: "",
      };
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setSelectedAddress(accounts[0]);
          localStorage.setItem("address", accounts[0]);
          console.log("addWalletListener");
          // history.push({
          //   pathname: `/`
          // });
          window.location.reload();
        } else {
          setSelectedAddress("");
          localStorage.setItem("address", "");
          toast("Connect to Metamask using the top right button.");
        }
      });
    } else {
      toast(
        "You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }

  function chainListener() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        console.log(_chainId);
        if (_chainId !== HARDHAT_NETWORK_ID) {
          localStorage.setItem("address", "");
          window.location.reload();
        }
        // if (_chainId === HARDHAT_NETWORK_ID) {
        //   console.log('Hello BC');
        //   window.location.reload();
        // }
      });
    } else {
      toast(
        "You must install Metamask, a virtual Ethereum wallet, in your browser."
      );
    }
  }
  function handlenavigation() {
    var objj = document.getElementById("forham");
    console.log("I am clicked");
    objj.classList.toggle("mystyle");
  }

  function handleActivetabs() {
    var obj = document.getElementsByClassName("nav-links");
    console.log(obj);
  }

  return (
    <div className="App">
      <header className="header-main">
        <div className="header-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 both-c">
                <div className="logo-c">
                  <Link to="/">
                    <img className="logo" src={logo} alt="" />
                  </Link>
                </div>
                <div className="cbtn-container">
                  <div className="col-sm-12 col-12 text-center wallet-section">
                    {!selectedAddress ? (
                      <div className="flat-search-btn  flex justify-content-center">
                        <div className="sc-btn-top" id="site-header">
                          <button
                            onClick={() => connectWalletPressed()}
                            id="connectbtn1"
                            className="sc-button header-slider style style-1 wallet fl-button pri-1 header-btn "
                          >
                            <img className="c-btn" src={cbtn} alt="" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flat-search-btn flex justify-content-center">
                        <div className="sc-btn-top" id="site-header">
                          <button
                            disabled
                            className="sc-button header-slider style style-1 wallet fl-button pri-1 header-btn connectedbtn"
                            id="connectbtn1"
                          >
                            <span>
                              {"Address: " +
                                String(selectedAddress).substring(0, 5) +
                                "..." +
                                String(selectedAddress).substring(
                                  String(selectedAddress).length - 3,
                                  String(selectedAddress).length
                                )}{" "}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="comic-logo-wrapper">
                  <Link to="/">
                    <img className="comics-logo" src={comics} alt="" />
                  </Link>
                </div>

                <img
                  className="hamburger"
                  src={hamburger}
                  alt=""
                  onClick={() => handlenavigation()}
                />
                <ul id="forham" className="nav main-navigation">
                  <li>
                    <Link className="nav-items" to="/">
                      COMICS
                    </Link>
                  </li>
                  <li className="character-li">
                    <Link to="#" className="nav-items">
                      CHARACTERS
                    </Link>

                    <ul className="mega-menu">
                      <div className="mega-bg">
                        <div className="list">
                          <Link to="/characters">All Characters</Link>
                          <Link to="/supervetteam">Teams & Groups</Link>
                          <Link to="/exploreall">Explore All</Link>
                        </div>

                        <h3 className="superverseheroes hideonmobile">
                          The Superverse Heroes
                        </h3>

                        <div className="comics-wrapper noborder nopadding hideonmobile">
                          <div className="single-character ">
                            <Link to="/bruceprofile" className="single-links">
                              <div className="single-character-wrapper">
                                <img
                                  className="single-comic-img"
                                  src={characterbruce}
                                  alt=""
                                />
                              </div>
                              <div className="for-border-anime"></div>
                              <div className="single-character-text-wrapper">
                                <h3 className="single-title">DR.BRUCE</h3>
                                <div className="nameandprice">
                                  <div className="name">Supert Vet</div>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="single-character">
                            <Link to="/characters" className="single-links">
                              <div className="single-character-wrapper">
                                <img
                                  className="single-comic-img"
                                  src={charactergibran}
                                  alt=""
                                />
                              </div>
                              <div className="for-border-anime"></div>
                              <div className="single-character-text-wrapper">
                                <h3 className="single-title">DR.Gibran</h3>
                                <div className="nameandprice">
                                  <div className="name">Supert Vet</div>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="single-character">
                            <Link to="/characters" className="single-links">
                              <div className="single-character-wrapper">
                                <img
                                  className="single-comic-img"
                                  src={characternina}
                                  alt=""
                                />
                              </div>
                              <div className="for-border-anime"></div>
                              <div className="single-character-text-wrapper">
                                <h3 className="single-title">Sister Nina</h3>
                                <div className="nameandprice">
                                  <div className="name">Supert Vet</div>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="single-character">
                            <Link to="/characters" className="single-links">
                              <div className="single-character-wrapper">
                                <img
                                  className="single-comic-img"
                                  src={charactersteel}
                                  alt=""
                                />
                              </div>
                              <div className="for-border-anime"></div>
                              <div className="single-character-text-wrapper">
                                <h3 className="single-title">DR.Steelshot</h3>
                                <div className="nameandprice">
                                  <div className="name">Supert Vet</div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li>
                    <Link to="/videos" className="nav-items">
                      VIDEOS
                    </Link>
                  </li>
                  <li>
                    <Link to="/news" className="nav-items">
                      NEWS
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-items" to="/competition">
                      Competition
                    </Link>
                  </li>
                  <li>
                    <a
                      className="nav-items"
                      href="https://supervet.io/"
                      target="_blank"
                    >
                      Supervet.io
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <div className="search-contain">
                  <input
                    className="search-field"
                    type="text"
                    placeholder="Search.."
                  />
                  <img className="search-icon" src={Searchico} alt="" />
                  {/* <Link to="/profile">
                    <img className="img-fluid prof-img" src={profileimg} alt="" />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Header;
