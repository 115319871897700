import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import arrowimg from "../../assets/imgs/news/arrow.png"
import post1 from "../../assets/imgs/news/post1.png"
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
import { Link } from "react-router-dom";
import post3secondimg from "../../assets/imgs/news/post3secondimage.png";
import Videoposter from "../../assets/imgs/news/videoposter.png";

export default class Singlepost3 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="backtocomics">
                    <div className="container">
                        <div className="row">
                            <Link to="/">
                                <div className="btc-wrap">
                                    <img className="arrow-blog" src={arrowimg} alt="" />
                                    <h3 className="backtocomics">Back to Comics</h3>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>


                <div className="main-bg blog-single-post">
                    <div className="main-bg-overlay">
                        <div className="container">
                            <div className="row">
                                <h3 className="category">Comics</h3>
                                <p className="published-date">
                                    Published June 13, 2022
                                </p>

                                <h1 className="single-post-title">Super Heroes Super Vet</h1>
                                <p className="short-details">
                                    Super Heroes
                                </p>

                            </div>
                        </div>


                        <div className="author-container">
                            <div className="container">
                                <div className="row">
                                    <p className="author">By Super Vet</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">



                                <p className="paragraph">
                                    Dr Steelshot is An animal lover Robotics engineer was once going normally for his job and had a car accident while saving a goat’s
                                    life on his way. He lost one eye and one hand. He managed to go home with his untreatable condition. He applied his robotics
                                    knowledge to him and ended up making a steel eye and steel hand but they didn’t function. One miracle morning, while preparing
                                    for the office, he got blessed with superpowers that made him able to see from his steel eye that can emit laser light and able
                                    to move his steel arm that can crush the walls. The Super Vet— Dr. SteelShot got his animal-rescue mission for life forever.
                                </p>






                                <div className="video-container">

                                    <video
                                        poster={Videoposter}
                                        id="vid-sec"
                                        playsInline
                                        controls
                                        disablePictureInPicture
                                        controlslist="nodownload noplaybackrate nopictureinpicture noremoteplayback "
                                        style={{ height: "100%", width: "90%" }}
                                    >
                                        <source
                                            src="https://d1ztdtzrrh7hlu.cloudfront.net/vet/video/game.mp4"
                                            type="video/mp4"
                                        ></source>
                                        Your browser does not support HTML5 video.
                                    </video>


                                </div>

                                <div className="row">
                                    <h3 className="trailer-tit">
                                        SUPERVET TRAILER
                                    </h3>
                                    <p>
                                        One of the most unique SUPER VET Legacy launches last year had to be the return of  VISION. Among the House of Ideas
                                        most haunting heroes, Marc Spector and his many alter-egos have gone on quite a journey in recent months. And this week,
                                        this multifaceted man reaches a merry milestone with VISION #200!
                                    </p>
                                </div>


                                {/* <div className="trailer-section">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <img className="trailer-img" src={trailer1} alt="" />
                                                    <h3 className="trailer-h">
                                                        BRUCE TRAILER
                                                    </h3>
                                                </div>

                                                <div className="col-sm-3">
                                                    <img className="trailer-img" src={trailer2} alt="" />
                                                    <h3 className="trailer-h">
                                                        Now Up To Date With Super Vet..!
                                                    </h3>
                                                </div>

                                                <div className="col-sm-3">
                                                    <img className="trailer-img" src={trailer3} alt="" />
                                                    <h3 className="trailer-h">
                                                        IVY TEAILER
                                                    </h3>
                                                </div>

                                                <div className="col-sm-3">
                                                    <img className="trailer-img" src={trailer4} alt="" />
                                                    <h3 className="trailer-h">
                                                        NEW WEPON TRAILER
                                                    </h3>
                                                </div>
                                            </div>
                                        </div> */}


                                {/* <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p> */}
                            </div>
                        </div>

                        {/* <div className="container">
                            <div className="row">
                                <h3 className="second-tit">
                                    WHAT IF DR.BRUCE HAD JOINED THE BOGGY ? (WHAT IF? #1)
                                </h3>
                                <img className="img-fluid" src={post3secondimg} alt="" />
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p>
                            </div>
                        </div> */}



                        <div className="container">
                            <div className="row">
                                {/* Related */}
                                <div className="call-to-action">

                                    <img src="../../public/assets/imgs/b1.png" alt="" />
                                    <div className="rectangle-cta">
                                        <div className="row">
                                            <div className="col-sm-3 ">
                                                <h4 className="call-to-tit">Related</h4>
                                            </div>
                                            <div className="col-sm-6 for-center">
                                                <p className="call-to-p">
                                                    Read To Earn : Read Super Vet Comics To earn rewards !
                                                </p>
                                            </div>
                                            <div className="col-sm-3 for-center"></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="character-spotlight">

                                    <div className="row">


                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot2} alt="" />
                                                <h3 className="spot-tit1">TRAILERS & EXTRAS</h3>
                                                <h3 className="spot-tit2">Who’s Gona Help Under Sea</h3>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot3} alt="" />
                                                <h3 className="spot-tit1">SUPER COMICS</h3>
                                                <h3 className="spot-tit2">First Appearances: Vision</h3>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot4} alt="" />
                                                <h3 className="spot-tit1">Amazing Comics</h3>
                                                <h3 className="spot-tit2">Super Heroes</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
