import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import arrowimg from "../../assets/imgs/news/arrow.png"
import post1 from "../../assets/imgs/news/post1.png"
import spot2 from "../../assets/imgs/characterspotlight/spot2.png";
import spot3 from "../../assets/imgs/characterspotlight/spot3.png";
import spot4 from "../../assets/imgs/characterspotlight/spot4.png";
import { Link } from "react-router-dom";
import img2 from "../../assets/imgs/news/post12.png"
import blog4a from "../../assets/imgs/news/blog4a.png"
import blog4b from "../../assets/imgs/news/blog4b.png"
import blog4c from "../../assets/imgs/news/blog4c.png"
import blog4d from "../../assets/imgs/news/blog4d.png"
import blog4e from "../../assets/imgs/news/blog4e.png"


function abc() {
    var obj = document.getElementById('video-sec');
    obj.setAttribute("src", "https://d1ztdtzrrh7hlu.cloudfront.net/vet/video/vr_gameplay.mp4");
}
export default class Singlepost4 extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="backtocomics">
                    <div className="container">
                        <div className="row">
                            <Link to="/">
                                <div className="btc-wrap">
                                    <img className="arrow-blog" src={arrowimg} alt="" />
                                    <h3 className="backtocomics">Back to Comics</h3>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>


                <div className="main-bg blog-single-post">
                    <div className="main-bg-overlay">
                        <div className="container">
                            <div className="row">
                                <h3 className="category">Comics</h3>
                                <p className="published-date">
                                    Published June 13, 2022
                                </p>

                                <h1 className="single-post-title">Who’s Gona Help Under Sea</h1>
                                <p className="short-details">
                                    Dr. Ivy Story
                                </p>

                            </div>
                        </div>


                        <div className="author-container">
                            <div className="container">
                                <div className="row">
                                    <p className="author">By Super Vet</p>
                                </div>
                            </div>
                        </div>


                        <div id="videos-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <div className="video-container">
                                            <video
                                                poster={blog4a}
                                                id="vid1"
                                                playsInline
                                                controls
                                                disablePictureInPicture
                                                controlslist="nodownload noplaybackrate nopictureinpicture noremoteplayback "
                                                style={{ height: "100%", width: "100%" }}
                                            >
                                                <source
                                                    src="https://d1ztdtzrrh7hlu.cloudfront.net/vet/video/game.mp4"
                                                    type="video/mp4"
                                                    id="video-sec"
                                                ></source>
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>






                                    </div>
                                    <div className="col-sm-2">
                                        <img className="img-fluid video-poster" src={blog4b} alt="" onClick={abc} />
                                        <img className="img-fluid video-poster" src={blog4c} alt="" />
                                        <img className="img-fluid video-poster" src={blog4d} alt="" />
                                        <img className="img-fluid video-poster" src={blog4e} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">



                                <p className="paragraph">
                                    A happily living ocean princess mermaid once cried a lot to have her fellow aquatic animals’
                                    dead bodies in her hands, who got killed by a giant beast mermaid. One day, when her killing
                                    became a routine in the ocean, the princess mermaid decided to catch and kill her red-handed
                                    but the villain ran away out of the ocean. It doesn’t belong to the ocean. The princess was
                                    struggling hard to catch the villain, she found an oyster shell. She opened it and became
                                    blessed with superpowers of making potions from plants, long jumping, and elasticity powers
                                    for rescuing aquatic animals. Super Vet— Dr. Ivy got her animal-rescue mission for life forever.
                                    <p className="short-details" style={{ paddingTop: '10px', fontSize: '20px' }}>
                                        Powers
                                    </p>
                                    <ul className="powersofchar">
                                        <li>Make potions from plants.</li>
                                        <li>Can cut roots of trees</li>
                                        <li>Rescues aquatic animals</li>
                                        <li>Long Jump</li>
                                        <li>Elasticity Power</li>
                                    </ul>

                                </p>



                                {/* <h3 className="second-tit">
                                    WHAT IF DR.BRUCE HAD JOINED THE BOGGY ? (WHAT IF? #1)
                                </h3>

                                <img className="img-fluid" src={post1} alt="" />
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p> */}
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                {/* <h3 className="second-tit">
                                    WHAT IF DR.BRUCE HAD JOINED THE BOGGY ? (WHAT IF? #1)
                                </h3>
                                <img className="img-fluid" src={img2} alt="" />
                                <p className="details">
                                    Have you tried Supervet Unlimited yet? It’s your all-access pass to over 28,000 Supervet comics, all available at your fingertips.
                                    Sign up now to enjoy your favorite stories just three months after they’re in stores.

                                </p> */}
                            </div>
                        </div>



                        <div className="container">
                            <div className="row">
                                {/* Related */}
                                <div className="call-to-action">

                                    <img src="../../public/assets/imgs/b1.png" alt="" />
                                    <div className="rectangle-cta">
                                        <div className="row">
                                            <div className="col-sm-3 ">
                                                <h4 className="call-to-tit">Related</h4>
                                            </div>
                                            <div className="col-sm-6 for-center">
                                                <p className="call-to-p">
                                                    Read To Earn : Read Super Vet Comics To earn rewards !
                                                </p>
                                            </div>
                                            <div className="col-sm-3 for-center"></div>
                                        </div>

                                    </div>
                                </div>

                                <div className="character-spotlight">

                                    <div className="row">


                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot2} alt="" />
                                                <h3 className="spot-tit1">TRAILERS & EXTRAS</h3>
                                                <h3 className="spot-tit2">Who’s Gona Help Under Sea</h3>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot3} alt="" />
                                                <h3 className="spot-tit1">SUPER COMICS</h3>
                                                <h3 className="spot-tit2">First Appearances: Vision</h3>
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="spot-wrapper">
                                                <img src={spot4} alt="" />
                                                <h3 className="spot-tit1">Amazing Comics</h3>
                                                <h3 className="spot-tit2">Super Heroes</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
