import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../assets/imgs/banners/b1.webp";
import slide2 from "../assets/imgs/banners/b2.webp";
import slide3 from "../assets/imgs/banners/b3.webp";
import slide4 from "../assets/imgs/banners/b4.webp";
import slide5 from "../assets/imgs/banners/b5.webp";

export default class Slider1c extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      Arrows: true,
    };
    return (
      <div className="main-slider-slider1">
        <Slider {...settings}>
          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">WEEKLY RELEASES</h3>
                <h1 className="main-tit">New Comics This Week</h1>
                <div className="btn-wrapper">
                  <a href="#" className="btn-slider">
                    Full List
                  </a>
                </div>
              </div>
            </div>
            <img className="slide1" src={slide1} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">super Comics</h3>
                <h1 className="main-tit">read super vet comics</h1>
                <div className="btn-wrapper">
                  <a href="#" className="btn-slider">
                    Full List
                  </a>
                </div>
              </div>
            </div>
            <img className="slide1" src={slide2} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">Let’s Read</h3>
                <h1 className="main-tit">daily super rewards</h1>
                <div className="btn-wrapper">
                  <a href="#" className="btn-slider">
                    Full List
                  </a>
                </div>
              </div>
            </div>
            <img className="slide1" src={slide3} alt="" />
          </div>

          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">Save the baby</h3>
                <h1 className="main-tit">save me i need help</h1>
                <div className="btn-wrapper">
                  <a href="#" className="btn-slider">
                    Full List
                  </a>
                </div>
              </div>
            </div>
            <img className="slide1" src={slide4} alt="" />
          </div>
          
          <div>
            <div className="container">
              <div className="text-wrapper">
                <h3 className="first-title">see through wall</h3>
                <h1 className="main-tit">i can see you boggy</h1>
                <div className="btn-wrapper">
                  <a href="#" className="btn-slider">
                    Full List
                  </a>
                </div>
              </div>
            </div>
            <img className="slide1" src={slide5} alt="" />
          </div>
        </Slider>
      </div>
    );
  }
}
